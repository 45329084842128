import React from 'react'
import './profile-container.styles.scss'

import ProfileForm from './profile-form.component'
import banner_no_logo from '@/assets/banner-no-logo.png';

const Profile = ({ profile }) => {

	return (
		<div className='profile-container'>
			<img className='img' src={banner_no_logo} alt="poolparty logo" />
			<div className='g-box'>
				<h2 className='profile-text'>Create a Profile</h2>
				<hr />
				<ProfileForm profile={profile} />
			</div>
		</div>
	)
}

export default Profile;