import React from 'react'
import './game-profile.styles.scss'

import ProfileForm from './profile-form.component'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'

const GameProfile = ({ gameProfile }) => {

	return (
		<div className='game-profile'>
			<div className='uk-accordion-title g-box head-box '>
				<span className='main'>
					<FontAwesomeIcon icon={faUser} className='head-icon' />
					My Game Profile
				</span>
				<hr />
				<div className='g-height-20'></div>
				<ProfileForm profile={gameProfile} />
			</div>
		</div>
	)
}

export default GameProfile;