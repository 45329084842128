import React from 'react'
import './suggested-props.styles.scss'

const SuggestedProps = () => {

	return (
		<div className='suggested-props'>
		
		</div>
	)
}

export default SuggestedProps