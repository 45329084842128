import React from 'react'
import './settings.styles.scss'

const Settings = () => {
  return (
    <div>
        
    </div>
  )
}

export default Settings