import React, { useState } from 'react'
import './manage-matchups.styles.scss'

import { Link } from 'react-router-dom'
import NewCompetitor from '../new-competitor/new-competitor.component'
import NewMatchup from '../new-matchup/new-matchup.component'
import MatchupList from '../matchup-list/matchup-list.component'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import UIkit from 'uikit'
import { UseCloudFunction } from '@/firebase-utils/firebase.utils'
import AdminMatchupItem from './admin-matchup-item.component'
import { deleteApp } from 'firebase/app'

const ManageMatchups = ({ matchups, competitors }) => {

	const [ matchup, setMatchup ] = useState(null)
	
    function editMatchup(m) {
        document.getElementById('new-matchup-col').scrollIntoView({'behavior':'smooth'})
        setMatchup(m)
    }

    async function deleteMatchup(m) {
        const approved = await UIkit.modal.confirm("Are you sure you want to delete this matchup? This cannot be undone.")
        .then(() => true, () => false);
        if (!approved) return;
        const res = await UseCloudFunction('deleteMatchup', {'matchup': m});
        if (res.error) {
            UIkit.modal.alert('Something went wrong: ' + res.error);
            return;
        }
        console.log(res);
    }

	return (
		<div className='manage-games'>
			<Link to='/gameadmin'>
				<button className='g-button'>Back</button>
			</Link>
			<div className='manage-games-content'>
				<div className='left-col' id='new-matchup-col'>
					<NewMatchup matchup={matchup} competitors={competitors} />
				</div>
				<div className='center-col'>
					{
                        (matchups)
                        ?
                        (matchups === 'none')
                        ?
                        <h3>No matchups yet...</h3>
                        :
                        Object.values(matchups).sort((a,b) => a.date < b.date ? 1 : -1)
                        .filter(m => m.type === 'matchup')
                        .map(m => (
                            <div key={m.id}>
                                <AdminMatchupItem matchup={m} editMatchup={editMatchup} deleteMatchup={deleteMatchup} />
                            </div>
                        ))
                        :
                        null                        
                    }
				</div>
				<div className='right-col'>
					<NewCompetitor />
				</div>
			</div>
		</div>
	)
}

export default ManageMatchups