import { initializeApp } from "firebase/app";
import { getFirestore, doc, setDoc } from "firebase/firestore";
import { getAuth } from 'firebase/auth';
import { getFunctions, httpsCallable } from "firebase/functions";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { setCurrentToken } from "@/state/slices/userSlice";
import { useDispatch } from "react-redux";
import { getDatabase } from "firebase/database";

const config = {
	apiKey: 'AIzaSyAkU8Iq70F2V8vd6AAsYm7ptI21ohVKrpI',
	authDomain: "college-bowl-pick-em.firebaseapp.com",
	databaseURL: "https://college-bowl-pick-em.firebaseio.com",
	projectId: "college-bowl-pick-em",
	storageBucket: "college-bowl-pick-em.appspot.com",
	messagingSenderId: "369258850095",
	appId: "1:369258850095:web:745a3b31a8509d837d63da",
	measurementId: "G-W7XCG1YQ1S"
};


const app = initializeApp(config);

/**
 * 
 * AUTHENTICATION
 * 
 */


export const auth = getAuth();

/**
*  MESSAGING
*/

const messaging = getMessaging();

export function getCurrentToken() {
    const token = getToken(messaging, {vapidKey: 'BPiFkinD8dRoEZwIJILUghb1tYHyDyraA0nCyZ8eUyzTCO4d41KdX5iw5EjFdGUbaqFfB9289HERUwQpRGC9hp8'})
    .then(async (currentToken) => {
        return currentToken
    })

    return token;
}

export async function UseGetMessageToken(setTokenFound, saveToken) {

    // const dispatch = useDispatch();
    console.log('getmessagetoken')
    const currentToken = await getCurrentToken();

    if (currentToken) {
        console.log('current token for client: ', currentToken);
        // dispatch(setCurrentToken(currentToken))
        setTokenFound(true);
        saveToken(currentToken)
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
    } else {
        console.log('No registration token available. Request permission to generate one.');
        setTokenFound(false);
        // shows on the UI that permission is required 
    }
    
    // return getToken(messaging, {vapidKey: 'BPiFkinD8dRoEZwIJILUghb1tYHyDyraA0nCyZ8eUyzTCO4d41KdX5iw5EjFdGUbaqFfB9289HERUwQpRGC9hp8'}).then(async (currentToken) => {
    //     console.log('getting token')
        
    // }).catch((err) => {
    //     console.log('An error occurred while retrieving token. ', err);
    //     // catch error while creating client token
    // });
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});


// export { signInWithGoogle }

/**
 * 
 * STORAGE
 * 
 */

// Get a reference to the storage service, which is used to create references in your storage bucket
// const storage = getStorage();

// export const uploadProfileImage = (name, type, blob) => {
	
// }


/**
 * 
 * SIGN OUT
 * 
 */

const signOut = async () => {
	await auth.signOut();
	return {'message': 'signed out'};
}

export { signOut }

/**
 * 
 * CREATE USER PROFILE DOC
 * 
 */

export const rtDb = getDatabase();

export const db = getFirestore();

export const createUserProfileDocument = async (userAuth, profile) => {
	// console.log(userAuth)
	if (!userAuth) return;
	await setDoc(doc(db, "users", userAuth.email), profile)
	return;
}

/**
 * 
 * CALL CLOUD FUNCTION
 * 
 */


export const UseCloudFunction = async (command, data) => {
    console.log(`cloud function: ${command}`)
	const functions = getFunctions();
	const processData = httpsCallable(functions, 'processData');
	const result = await processData({ 
		command: command,
		data: data
	})
	const resData = result.data;
	return resData;
}