import React from 'react'
import './admin-landing.styles.scss'

import { useSelector } from 'react-redux'

import NextMatchup from '../next-matchup/next-matchup.component'
import PickAdmin from './pick-admin/pick-admin.component'
import HuntAdmin from './hunt-admin/hunt-admin.component'
import UIkit from 'uikit'
import { UseCloudFunction } from '@/firebase-utils/firebase.utils'
import { useNavigate } from 'react-router-dom'

const AdminLanding = ({ picks, matchups, leaders, hof, notifications }) => {

	const currentGame = useSelector(state => state.gameData.currentGame);
    const navigate = useNavigate();

    async function archiveWorld() {
        let approved = false;
        await UIkit.modal.confirm('Are you sure you want to archive this game? You will still be able to access this game in your archived list, but your players will no longer have access.')
        .then(() => {
            console.log('confirmed')
            approved = true;
        }, () => {
            console.log('rejected')
        })
        console.log(approved)
        if (!approved) { return; }
        const res = await UseCloudFunction('archiveGame', {'gameId': currentGame.gameId})
        console.log(res)
        navigate('/home')
    }
	
	return (
		<div className='admin-landing'>
			<div className='left-col'>
				{
					(currentGame && currentGame.type === 'pick')
					?
						<PickAdmin />
					:
						(currentGame && currentGame.type === 'hunt')
						?
							<HuntAdmin />
						:
							''
				}
				
			</div>
			<div className='right-col'>
				{
					(currentGame && currentGame.type === 'pick')
					?
						<NextMatchup matchups={matchups}  />
					:
						(currentGame && currentGame.type === 'hunt')
						?
							''
						:
							''
				}
				
			</div>
		</div>
	)
}

export default AdminLanding;