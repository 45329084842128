import React from 'react'
import './hunt-admin.styles.scss'
import { Link } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarDays, faBinoculars, faArchive } from '@fortawesome/free-solid-svg-icons'

const HuntAdmin = ({ archiveWorld }) => {

	return (
		<div className='hunt-admin'>
			<div className='admin-buttons'>
				<Link to='managechallenges' className='admin-button'>
					<button className='g-button no-pad' style={{'width':'100%'}}>
						<table>
							<tbody>
								<tr>
									<td><FontAwesomeIcon icon={faCalendarDays} size="lg" /></td>
									<td>Manage Challenges</td>
								</tr>
							</tbody>
						</table>
					</button>
				</Link>
				<Link to='competitors' className='admin-button'>
					<button className='g-button no-pad' style={{'width':'100%'}}>
						<table>
							<tbody>
								<tr>
									<td><FontAwesomeIcon icon={faBinoculars} size="lg" /></td>
									<td>Add Competitors</td>
								</tr>
							</tbody>
						</table>
					</button>
				</Link>
                <a onClick={() => archiveWorld()} className='admin-button'>
					<button className='g-button no-pad' style={{'width':'100%'}}>
						<table>
							<tbody>
								<tr>
									<td><FontAwesomeIcon icon={faArchive} size="lg" /></td>
									<td>Archive World</td>
								</tr>
							</tbody>
						</table>
					</button>
				</a>
			</div>
			
		</div>
	)
}

export default HuntAdmin;