import React, { useEffect } from 'react'
import './logout.styles.scss'

import { useDispatch } from 'react-redux'
import { resetGameData } from '@/state/slices/gameSlice'
import { signOut } from '@/firebase-utils/firebase.utils'
import { resetUserData } from '@/state/slices/userSlice'
import { useNavigate } from 'react-router-dom'

const LogoutPage = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        async function clearData() {
            dispatch(resetGameData())
            dispatch(resetUserData())
            const signout = await signOut();
            console.log(signout)
            setTimeout(() => {
                navigate('/');
            }, 2000);
        }
        clearData();
    }, [])

    return (
        <div className='logout'>
            <div className="loader-spinner"></div>
        </div>
    )
}

export default LogoutPage