import React, { useEffect, useState } from 'react';
import './matchup-item.styles.scss';

import { faLock, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import streak_bonus from '@/assets/streak-bonus.png';
import { formatNumber } from '@/utils/formatNumber';

export function getPercentages(picks) {
    if (!picks) return [0,0];
    const c1 = (Object.values(picks).filter(p => p.pick === 'comp1').length / Object.values(picks).length * 100).toFixed(0);
    const c2 = (Object.values(picks).filter(p => p.pick === 'comp2').length / Object.values(picks).length * 100).toFixed(0);
    const w1 = formatNumber(Object.values(picks).filter(p => p.pick === 'comp1').reduce((acc, p) => acc + p.wager, 0))
    const w2 = formatNumber(Object.values(picks).filter(p => p.pick === 'comp2').reduce((acc, p) => acc + p.wager, 0))
    return [c1, c2, w1, w2];
}

export const MatchupItem = ({ matchup }) => {

    const currentGame = useSelector(state => state.gameData.currentGame);
    const userProfile = useSelector(state => state.userData.profile);
    const [ pick, setPick ] = useState({});

    useEffect(() => {
        if (!matchup) return;
        setPick(matchup.picks && matchup.picks[userProfile.appId] ? matchup.picks[userProfile.appId] : {})
    }, [matchup])

    return (
        <div className='g-box matchup-item'>
            
            <div className='item-content'>
                <div className={`img-div ${pick.pick === 'comp1' ? 'picked' : ''}`}>
                    <img src={matchup.comp1Icon} className='matchup-icon' loading="lazy" />
                    <div className={`pick-percentage ${getPercentages(matchup.picks)[0] >= 50 ? 'high': 'low'}`}>
                        {`${getPercentages(matchup.picks)[0]}%`}
                    </div>
                </div>
                <div className='desc-div'>
                    <div className='desc-title'>{matchup.name}</div>
                    <div className='desc-date'>
                        {format(new Date(matchup.date), "E, Pp")}
                    </div>
                    {
                        (pick.wager) &&
                        <div>
                            <b>Wager: {pick.wager}</b>
                        </div>
                    }
                    {
                        (matchup.date < new Date().getTime()) &&
                        <div className='center-col'><FontAwesomeIcon icon={faLock} /></div>
                    }
                </div>
                <div className={`img-div ${pick.pick === 'comp2' ? 'picked' : ''}`}>
                    <img src={matchup.comp2Icon} className='matchup-icon' loading="lazy" />
                    <div className={`pick-percentage ${getPercentages(matchup.picks)[1] >= 50 ? 'high': 'low'}`}>
                        {`${getPercentages(matchup.picks)[1]}%`}
                    </div>
                </div>
            </div>
        </div>
    )
}

export const PassedMatchupItem = ({ matchup }) => {

    const userProfile = useSelector(state => state.userData.profile);
    const [ pick, setPick ] = useState({});
    const [ pick1Bg, setPick1Bg ] = useState('');
    const [ pick2Bg, setPick2Bg ] = useState('');

    useEffect(() => {
        if (!matchup || matchup.bonus) return;
        setPick(matchup.picks && matchup.picks[userProfile.appId] ? matchup.picks[userProfile.appId] : {})
    }, [matchup])

    useEffect(() => {
        if (!pick.pick || !matchup) return;
        if (matchup.winner) {
            if (pick.pick === matchup.winner) {
                if (matchup.winner === 'comp1') {
                    setPick1Bg('picked-correct');
                    setPick2Bg('');
                } else {
                    setPick1Bg('');
                    setPick2Bg('picked-correct');
                }
            } else {
                if (matchup.winner === 'comp1') {
                    setPick1Bg('');
                    setPick2Bg('picked-wrong');
                } else {
                    setPick1Bg('picked-wrong');
                    setPick2Bg('');
                }
            }
        } else if (pick.pick === 'comp1') {
            setPick1Bg('picked');
            setPick2Bg('');
        } else if (pick.pick === 'comp2'){
            setPick1Bg('');
            setPick2Bg('picked');
        }
    }, [pick, matchup])

    if (matchup.bonus) {
        return (
            <div className='g-box bonus-box'>
                <img src={streak_bonus} loading="lazy" />
                <div className='bonus-data'>
                    <div className='title'>Streak Bonus!</div>
                    <div className='meta'>{matchup.streak} in a Row</div>
                    <div className='result-pts'>+{matchup.earned}</div>
                </div>
                <img src={streak_bonus} />
            </div>
        )
    }

    return (
        <div className='g-box matchup-item'>
            <div className='item-content passed'>
                <div className='desc-title'>{matchup.name}</div>
                <div className='desc-date'>
                    {format(new Date(matchup.date), "E, Pp")}
                </div>
                {
                    (matchup.winner) 
                    ?
                    <div className='result'>Result: {pick.pick === matchup.winner ? <span className='win'>+{pick.wager}</span> : <span className='loss'>-{pick.wager}</span>}</div>
                    :
                    (pick.wager)
                    ?
                    <div className='wager'>Wager: {pick.wager}</div>
                    :
                    null
                }
                <div className='details'>
                    <div className={`img-div ${pick1Bg}`}>
                        <img src={matchup.comp1Icon} className='passed-matchup-icon' loading="lazy" />
                        {
                            (matchup.comp1Details) &&
                            <div className='comp-details'>{matchup.comp1Details}</div>
                        }
                    </div>
                    <div className={`img-div ${pick2Bg}`}>
                        <img src={matchup.comp2Icon} className='passed-matchup-icon' loading="lazy" />
                        {
                            (matchup.comp2Details) &&
                            <div className='comp-details'>{matchup.comp2Details}</div>
                        }
                    </div>
                    <div className={`pick-percentage ${getPercentages(matchup.picks)[0] >= 50 ? 'high': 'low'}`}>
                        {`${getPercentages(matchup.picks)[0]}%`}
                        <div className='meta'>Tot Wager: {getPercentages(matchup.picks)[2]}</div>
                    </div>
                    <div className={`pick-percentage ${getPercentages(matchup.picks)[1] >= 50 ? 'high': 'low'}`}>
                        {`${getPercentages(matchup.picks)[1]}%`}
                        <div className='meta'>Tot Wager: {getPercentages(matchup.picks)[3]}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}