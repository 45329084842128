import React, { useEffect, useState } from 'react'
import './competitor-list.styles.scss'
import { useSelector } from 'react-redux'

const CompetitorList = ({ competitors }) => {

    const [ compArray, setCompArray ] = useState(null);

	useEffect(() => {
        if (!competitors) return;
        setCompArray(Object.values(competitors).sort((a,b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1));
    }, [competitors])
    
	
	return (
		<div className='competitor-list'>
		{
            (compArray) &&
			compArray.map(comp => (
				<div key={comp.id} className='comp-container'>
					<div className='g-box'>
						<table>
							<tbody>
								<tr>
									<td className='icon-td'>
										<img src={comp.icon} />
									</td>
									<td className='text'>
										{comp.name}
									</td>
									<td className="right">
										delete
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			))
		}
		</div>
	)
}

export default CompetitorList