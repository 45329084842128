import React, { useState } from 'react'
import './manage-props.styles.scss'
import { Link } from 'react-router-dom'

import NewProp from '../new-prop/new-prop.component'
import PropsList from '../props-list/props-list-OLD.component'
import SuggestedProps from '../suggested-props/suggested-props.component'
import AdminPropItem from './admin-prop-item.component'
import UIkit from 'uikit'
import { UseCloudFunction } from '@/firebase-utils/firebase.utils'

const ManageProps = ({ matchups }) => {

    const [ propToEdit, setPropToEdit ] = useState(null);

    function editProp(m) {
        setPropToEdit(m);
    }

    async function deleteProp(m) {
        const approved = await UIkit.modal.confirm("Are you sure you want to delete this prop? This cannot be undone.")
        .then(() => true, () => false);
        if (!approved) return;
        const res = await UseCloudFunction('deleteMatchup', {'matchup': m});
        if (res.error) {
            UIkit.modal.alert('Something went wrong: ' + res.error);
            return;
        }
        console.log(res);
    }

	return (
		<div className='manage-props'>
			<Link to='/gameadmin'>
				<button className='g-button'>Back</button>
			</Link>
			<div className='manage-props-content'>
				<div className='left-col'>
					<NewProp matchups={matchups} propToEdit={propToEdit} />
				</div>
				<div className='center-col'>
					{
                        (matchups)
                        ?
                        (matchups === 'none')
                        ?
                        <h3>No props yet...</h3>
                        :
                        Object.values(matchups).sort((a,b) => a.date < b.data ? 1 : -1)
                        .filter(m => m.type === 'prop')
                        .map(m => (
                            <div key={m.id}>
                                <AdminPropItem matchup={m} editProp={editProp} deleteProp={deleteProp}  />
                            </div>
                        ))
                        :
                        null
                    }
				</div>
				<div className='right-col'>
					<SuggestedProps />
				</div>
			</div>
		</div>
	)
}

export default ManageProps