import React, { useEffect, useState } from 'react';
import './prop-items.styles.scss';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import check_box  from '@/assets/check-box.png';
import crossed from '@/assets/crossed.png'
import { formatNumber } from '@/utils/formatNumber';

function getTotals(picks) {
    if (!picks) return [0,0];
    const c1 = (Object.values(picks).filter(p => p.pick === 'comp1').reduce((acc, p) => { return acc + p.wager }, 0)) 
    const c2 = (Object.values(picks).filter(p => p.pick === 'comp2').reduce((acc, p) => { return acc + p.wager }, 0))
    return [formatNumber(c1), formatNumber(c2)];
}

export const PropItem = ({ prop }) => {

    const userProfile = useSelector(state => state.userData.profile);
    const [ pick, setPick ] = useState({})

    useEffect(() => {
        if (!prop || !prop.picks) return;
        if (prop.picks[userProfile.appId]) {
            setPick(prop.picks[userProfile.appId]);
        } 
    }, [prop])

    return (
        <div className='g-box prop-item'>
            <div className='desc-div'>
                <div className="desc-title">
                    {prop.name}
                </div>
                <div className="desc-desc">
                    {prop.desc}
                </div>
                {
                    (prop.date > new Date().getTime())
                    ?
                    <div className='date'>Locks: {format(new Date(prop.date), "E, Pp")}</div>
                    :
                    <div className='date'><FontAwesomeIcon icon={faLock} /></div>
                }
                <div className="g-space-0-5"></div>
                <div className='details'>
                    <div className={`pick-box-div`}>
                        <div className={`pick-box ${pick.pick === 'comp1' ? 'picked' : ''}`}>
                            <div>{prop.comp1}</div>
                            
                        </div>
                    </div>
                    <div className={`pick-box-div`}>
                        <div className={`pick-box ${pick.pick === 'comp2' ? 'picked' : ''}`}>
                            <div>{prop.comp2}</div>
                            
                        </div>
                    </div>
                    <div className='meta'>Tot Wagers: {getTotals(prop.picks)[0]}</div>
                    <div className='meta'>Tot Wagers: {getTotals(prop.picks)[1]}</div>
                </div>
                {
                    (pick.wager) &&
                    <div><b>Wager: {pick.wager}</b></div>
                }
                
            </div>
        </div>
    )
}

export const CompletePropItem = ({ prop }) => {

    const userProfile = useSelector(state => state.userData.profile);
    const [ pick, setPick ] = useState({})
    const [ pick1Bg, setPick1Bg ] = useState('');
    const [ pick2Bg, setPick2Bg ] = useState('');

    useEffect(() => {
        if (!prop || !prop.picks) return;
        if (prop.picks[userProfile.appId]) {
            setPick(prop.picks[userProfile.appId]);
        } 
    }, [prop])

    useEffect(() => {
        if (!pick || !pick.pick || !prop) return;
        console.log(pick);
        if (prop.winner) {
            if (pick.pick === prop.winner) {
                if (prop.winner === 'comp1') {
                    setPick1Bg('picked-correct');
                    setPick2Bg('');
                } else {
                    setPick1Bg('');
                    setPick2Bg('picked-correct');
                }
            } else {
                if (prop.winner === 'comp1') {
                    setPick1Bg('');
                    setPick2Bg('picked-wrong');
                } else {
                    setPick1Bg('picked-wrong');
                    setPick2Bg('');
                }
            }
        } else if (pick.pick === 'comp1') {
            setPick1Bg('picked');
            setPick2Bg('');
        } else if (pick.pick === 'comp2'){
            setPick1Bg('');
            setPick2Bg('picked');
        }
    }, [pick, prop])

    return (
        <div className='g-box prop-item passed'>
            <div className='desc-div'>
                <div className="desc-title">
                    {prop.name}
                </div>
                <div className="desc-desc">
                    {prop.desc}
                </div>
                {
                    (new Date().getTime() < prop.date) 
                    ?
                    <div>Locks: {format(new Date(prop.date), "E, Pp")}</div>
                    :
                    <div className='center-col'><FontAwesomeIcon icon={faLock} /></div>
                } 
                {
                    (prop.winner && pick.pick) 
                    ?
                    <div className='result'>Result: {pick.pick === prop.winner ? <span className='win'>+{pick.wager}</span> : <span className='loss'>-{pick.wager}</span>}</div>
                    :
                    (pick.wager) &&
                    <div><b>Wager: {pick.wager}</b></div>
                }
                <div className='details'>
                    <div className={`pick-box-div`}>
                        <div className={`pick-box ${pick1Bg}`}>
                            {prop.comp1}
                            {
                                (prop.winner === 'comp1' && !pick.pick) 
                                ?
                                <>
                                    <div className="g-space-0-5"></div>
                                    <img className='check' src={check_box} />
                                </>
                                :
                                (prop.winner === 'comp2' && !pick.pick) 
                                ?
                                <>
                                    <div className="g-space-0-5"></div>
                                    <img className='crossed' src={crossed} />
                                </>
                                :
                                ''
                            }
                        </div>
                    </div>
                    <div className={`pick-box-div`}>
                        <div className={`pick-box ${pick2Bg}`}>
                            {prop.comp2}
                            {
                                (prop.winner === 'comp2' && !pick.pick) 
                                ?
                                <>
                                    <div className="g-space-0-5"></div>
                                    <img className='check' src={check_box} />
                                </>
                                :
                                (prop.winner === 'comp1' && !pick.pick) 
                                ?
                                <>
                                    <div className="g-space-0-5"></div>
                                    <img className='crossed' src={crossed} />
                                </>
                                :
                                ''
                            }
                        </div>
                    </div>
                    <div className='meta'>Tot Wagers: {getTotals(prop.picks)[0]}</div>
                    <div className='meta'>Tot Wagers: {getTotals(prop.picks)[1]}</div>
                </div>
               
            </div>
        </div>
    )
}