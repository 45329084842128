import React, { useState, useEffect, useRef } from 'react'
import './new-challenge.styles.scss'

import { useSelector, useDispatch } from 'react-redux'
import { setChallenges } from '@/state/slices/gameSlice'

import flatpickr from 'flatpickr'
import "croppie/croppie.css"
import Croppie from "croppie"

import UIkit from 'uikit'
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { getDatabase, ref as dbRef, set } from "firebase/database"

const NewChallenge = ({ challenge, setChallenge }) => {

	const currentGame = useSelector(state => state.gameData.currentGame)
	const challenges = useSelector(state => state.gameData.challenges)
	const storage = getStorage()
	const dispatch = useDispatch()
	const [ image, setImage ] = useState(null)
	const stateRef = useRef()
	stateRef.current = challenge
	let fp1

	useEffect(() => {
		const date1Element = document.getElementById('date')
		fp1 = flatpickr(date1Element, 
		{
			altInput: true,
			altFormat: "F j, Y, h:i K",
			enableTime: true,
			dateFormat: "Y-m-d h:i K",
			onChange: (dates) => {
				saveChallengeToState(new Date(dates[0]).getTime(),'date')
			}
		});
	}, [])

	const saveChallengeToState = (e, cat) => {
		setChallenge({...stateRef.current, ...{[cat]: e}})
	}

	const destroyCroppie = () => {
		const el = document.getElementById("uploaded-image")
		el.innerHTML = ''
		el.classList.remove("croppie-container");
	}

	const handleUpload = (file) => {
		destroyCroppie()
		if (file.type.match(/image.*/)) {
				
			const fileType = file.type;
			const fileName = file.name;
			console.log('An image has been loaded');
			console.log(fileType)
			// Load the image
			if (fileType.indexOf('gif') == -1) {
				const newFile = window.URL.createObjectURL(file)
				const el = document.getElementById("uploaded-image")
				let croppieInstance
				if (el) {
					croppieInstance = new Croppie(el, {
						viewport: { width: 150, height: 150 },
						boundary: { width: 150, height: 150 },
						showZoomer: true,
						enableOrientation: true
					});
					croppieInstance.bind({
						url: newFile,
					});
				}
				setImage({croppie: true, url: newFile, type: fileType, name: fileName, file: croppieInstance})
				return;
			}
			const newFile = window.URL.createObjectURL(file)
			setImage({url:newFile, file: file})
			const img = `<img src="${newFile}" className="create-icon" />`
			const el = document.getElementById("uploaded-image")
			el.innerHTML = img
			return;
		}
		UIkit.modal.alert('Please upload an image file.')
		return;
	}

	
	async function handleSubmit(e) {
		e.preventDefault()
		if (!challenge.name || !challenge.dir || !challenge.date || !challenge.points) {
			UIkit.modal.alert('Please complete the form before saving the challenge.')
			return;
		}
		let fileToUpload
		if (image.croppie) {
			fileToUpload = await image.file.result({
				type:'blob',
				size: 512
			})
		
			const now = new Date().getTime();
			const iconRef = ref(storage, `uploaded-competitor-icons/${now}-${image.name}`)
			const metadata = {
				contentType: image.type,
			};
			uploadBytes(iconRef, fileToUpload, metadata).then((snapshot) => {
				getDownloadURL(iconRef)
				.then((url) => {
					finalizeUpload(url)
					return;
				})
			})
		} else {
			finalizeUpload(challenge.icon)
		}
	}

	async function cancelSave() {
		
	}

	async function finalizeUpload(url) {
		
		// console.log(e)
		let challengeId;
		if (challenge.id) {
			challengeId = challenge.id
		} else {
			challengeId = new Date().getTime()
		}
		const db = getDatabase();
		
		challenge.id = Number(challengeId)
		challenge.icon = url
		// challenge.type = '?'
		await set(dbRef(db, `challenges/${current.gameId}/${challengeId}`), challenge)
		setChallenge(null)
		dispatch(setChallenges({...challenges, ...{[challengeId]: challenge}}))
		const date1Element = document.getElementById('date')
		let fp1 = flatpickr(date1Element, 
			{
				altInput: true,
	            altFormat: "F j, Y, h:i K",
	            enableTime: true,
	            dateFormat: "Y-m-d h:i K",
				onChange: (dates) => {setChallenge({...challenge, ...{'date': new Date(dates).getTime()}})}
			}
		);
		fp1.clear();
		document.getElementById('new-challenge-div').scrollIntoView({'behavior':'smooth'})
		
	}
	
	return (
		<div className='new-challenge' id='new-challenge-div'>
			<div className='g-box'>
				<h4>Add a new challenge</h4><hr />
				<form onSubmit={handleSubmit}>
                    <div className="uk-margin">
						<label>Challenge Name</label>
						<input type="text" className="uk-input" value={(challenge && challenge.name) ? challenge.name : ''} 
							onChange={e => saveChallengeToState(e.target.value, 'name')} />
					</div>
					<div className="uk-margin">
						<label>Challenge Directions</label>
						<textarea type="text" className="uk-textarea" value={(challenge && challenge.dir) ? challenge.dir : ''} 
							onChange={e => saveChallengeToState(e.target.value, 'dir')} ></textarea>
					</div>
					<div className="g-grid">
						
						<div className='g-half-col middle'>
						
							<div className="upload-button">
								<div data-uk-form-custom style={{'cursor': 'pointer'}}>
									<input 
										type="file" 
										id="imageUpload" 
										accept="image/*"
										onChange={(e) => handleUpload(e.target.files[0])}  />
									<button 
										className="uk-button" 
										type="button" 
										tabIndex="-1"
									>Upload Image</button>
								</div>
							</div>
						</div>
						<div className='g-half-col middle'>
							<div id="uploaded-image" className="upload-div">
								{
									(image) ? <img src={image.url || image} className="upload-image" />
									: <img src="/assets/placeholder-icon.jpg" className="upload-image"/>
								}
							</div>
						</div>
					</div>

					<div className="uk-margin">
						<label>Date & Time to Go Live</label>
						<input className="uk-input flatpickr flatpickr-input" type="text" id="date" />
					</div>
					
					<div className="uk-margin">
						<label>Point Value</label>
						<select className="uk-select" value={(challenge && challenge.points) ? challenge.points : ''} 
								onChange={e => saveChallengeToState(e.target.value, 'points')}>
							<option value="">Choose a Point Value</option>
							<option value="100">100</option>
							<option value="200">200</option>
							<option value="300">300</option>
							<option value="400">400</option>
							<option value="500">500</option>
						</select>
					</div>
					<div className="uk-margin">
						<label>
							<input className="uk-checkbox" type="checkbox" checked={(challenge && challenge.upload) ? challenge.upload : false}
								onChange={e => saveChallengeToState(e.target.checked, 'upload')} />
								 &nbsp;&nbsp;Require an upload for this challenge?
						</label>
					</div>
					<div className="uk-margin">
						<label>
							<input className="uk-checkbox" type="checkbox" checked={(challenge && challenge.approval) ? challenge.approval : false}
								onChange={e => saveChallengeToState(e.target.checked, 'approval')} />
								 &nbsp;&nbsp;Require approval to earn points?
						</label>
					</div>

					<hr />
					<div className='new-challenge-buttons'>
						<button className='g-button small-pad' type='button' onClick={() => cancelSave()}>Cancel</button>
						<button className='g-button no-pad' type='submit' style={{'backgroundColor':'#03C04A'}} >Save</button>
					</div>
                </form>
			</div>
		</div>
	)
}

export default NewChallenge;