import React, { useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import './profile-form.styles.scss'
import { useNavigate } from 'react-router-dom'
import { setProfile } from '@/state/slices/userSlice'

import { auth, createUserProfileDocument, UseCloudFunction } from '@/firebase-utils/firebase.utils'

import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'

import ProfileIcons from '@/components/profile-icon/profile-icon.component'

import { RandomString } from '@/utils/random.utils'

import UIkit from 'uikit'

const ProfileForm = ({ profile }) => {

	const dispatch = useDispatch()
	const navigate = useNavigate()
    const userProfile = useSelector(state => state.userData.profile);
	// const userProfile = useSelector(state => state.userData.profile)
	// const gameProfile = useSelector(state => state.userData.gameProfile)
	// const profileIcon = useSelector(selectProfileIcon)
	const currentGame = useSelector(state => state.gameData.currentGame);
	// const [ profileIcon, setProfileIcon ] = useState((gameProfile) ? gameProfile.icon : (userProfile) ? userProfile.icon : null)
	// const [fName, setFName] = useState((gameProfile) ? gameProfile.fName : (userProfile) ? userProfile.fName : '')
	// const [lName, setLName] = useState((gameProfile) ? gameProfile.lName : (userProfile) ? userProfile.lName : '')
	// const [location, setLocation] = useState((gameProfile) ? gameProfile.loc : (userProfile) ? userProfile.loc : '')
	const currentUser = useSelector(state => state.userData.currentUser);
    const [ formData, setFormData ] = useState({})
    const [ loading, setLoading ] = useState(false);
    const [ icon, setIcon ] = useState(null);
    const dataRef = useRef();

	const storage = getStorage();

    useEffect(() => {
        if (profile) {
            dataRef.current = {...profile};
            setFormData(dataRef.current);
            setIcon(profile.icon);
        }
    }, [profile])

    function saveData(key, value) {
        dataRef.current = {...dataRef.current, ...{[key]: value}}
        setFormData(dataRef.current);
    }

	const handleSubmit = async (e) => {
		e.preventDefault()
        const tempData = {...dataRef.current};
        
        if (!tempData.fName || !tempData.lName || !icon) {
            UIkit.modal.alert('Please include your name and an icon before saving your profile!')
            return;
        }
		// console.log(fName)
		if (icon.upload && icon.type.indexOf('gif') == -1) {
			icon.croppie.result({
				type:'blob',
				size: {'width': 312, 'height': 312},
                quality: 0.7,
			})
			.then((blob) => {
				uploadImage(icon, blob)
				return;
			})
		} else if (icon.upload) {
			uploadImage(icon, icon.file)
			return;
		} else if (icon.url) {
			saveProfile(icon.url)
			return;
		} else if (icon) {
			saveProfile(icon)
		}
	}
				
	const uploadImage = (profileIcon, blob) => {		
		const now = new Date().getTime();
		let profileRef;
        const metadata = {
			contentType: profileIcon.type,
		};
        if (currentGame) {
            metadata.customMetadata = {
                gameId: currentGame.gameId,
            }
            profileRef = ref(storage, `${currentGame.gameId}/profile_icons/${now}-${profileIcon.name}`)
        } else {
            profileRef = ref(storage, `profile_icons/${now}-${profileIcon.name}`)
        }
        
		uploadBytes(profileRef, blob, metadata).then((snapshot) => {
			getDownloadURL(profileRef)
			.then((url) => {
				saveProfile(url)
				return;
			})
		})
	}
				
	const saveProfile = async (url) => {
        setLoading(true);
        const tempData = {...dataRef.current};
        const now = new Date().getTime();
		// const submitButton = document.getElementById('save-profile-button')
		// submitButton.disabled = true;
		// const width = submitButton.offsetWidth
		// console.log(width)
		// submitButton.innerHTML = '<div data-uk-spinner="ratio: .5"></div>'
		// submitButton.style.width = `${width}px`;
		let appId = '';
        if (userProfile) {
            appId = userProfile.appId;
        } else {
            appId = RandomString(20);            
        }
        if (currentGame) {	
            const newProfile = {
				fName: tempData.fName,
				lName: tempData.lName,
				loc: tempData.loc,
				name: `${tempData.fName} ${tempData.lName}`,
				icon: url,
			}
			const data = {
				newProfile: newProfile,
				gameId: currentGame.gameId,
				appId: userProfile.appId,
			}
			const update = await UseCloudFunction('updateProfile', data);
			console.log(update);
			navigate('/game');
			UIkit.modal.alert('Your profile for this specific game has been updated.');
            return;
		} else {
            const newProfile = {
				fName: tempData.fName,
				lName: tempData.lName,
				loc: tempData.loc,
				email: currentUser.email,
				name: `${tempData.fName} ${tempData.lName}`,
				timestamp: now,
				uid: currentUser.uid,
				icon: url,
				appId: appId,
			}
            const data = {
                newProfile: newProfile,
				appId: newProfile.appId,
            }
            const update = await UseCloudFunction('updateProfile', data)
			console.log(update)
    		navigate('/home')
			UIkit.modal.alert('Your profile has been updated.');
            return;
		}
	}

	const handleCancel = () => {
		if (currentGame) {
			navigate('/game')
		} else if (userProfile) {
			navigate('/home')
		} else {
			navigate('/logout')
			return;
		}
	}


	return (
		<div className='profile-form'>	
			<form >
				<div className="uk-grid-small" data-uk-grid>
					
					<div className="uk-width-1-2@s">
						<label>First Name</label>
						<input className="uk-input" type="text" value={formData.fName ?? ''} onChange={(e) => saveData('fName', e.target.value)} />
					</div>
					
					<div className="uk-width-1-2@s">
						<label>Last Name</label>
						<input className="uk-input" type="text" value={formData.lName ?? ''} onChange={(e) => saveData('lName', e.target.value)} />
					</div>
					
					<div className="uk-width-1-1">
						<label>Tell us where you live (for the folks who don't know you...)</label>
						<input className="uk-input" type="text" value={formData.loc ?? ''} onChange={(e) => saveData('loc', e.target.value)} />
					</div>
				</div>

				<ProfileIcons icon={icon} setIcon={setIcon} />
				
				<div className='form-buttons'>
					
					<button className='g-button' onClick={() => handleCancel()}>
						Cancel
					</button>
					
                    {
                        !loading
                        ?
                        <button className='g-button' id='save-profile-button' onClick={(e) => handleSubmit(e)}>
                            Save Profile
                        </button>
                        :
                        <button className='g-button' id='save-profile-button'><div data-uk-spinner="ratio: .5"></div></button>
                    }
				</div>
			</form>
		</div>
	)
}

export default ProfileForm