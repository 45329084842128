import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import './new-competitor.styles.scss'

import "croppie/croppie.css"
import Croppie from "croppie"
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { getDatabase, ref as dbRef, set } from "firebase/database"

import UIkit from 'uikit'
import { UseCloudFunction } from '@/firebase-utils/firebase.utils'

const NewCompetitor = () => {

	const [name, setName] = useState('')
	const [image, setImage] = useState('')
	const currentGame = useSelector(state => state.gameData.currentGame)
	const storage = getStorage()
		
	const destroyCroppie = () => {
		const el = document.getElementById("uploaded-image")
		el.innerHTML = ''
		el.classList.remove("croppie-container");
	}
	
	function handleUpload(file) {
		const sel = document.getElementById('selected-image')
		sel.classList.add('hide')
		destroyCroppie()
		const fileType = file.type;
		const fileName = file.name;
		if (file.type.match(/image.*/)) {
			console.log('An image has been loaded');
			console.log(fileType)
			// Load the image
			if (fileType.indexOf('gif') == -1) {
				const newFile = window.URL.createObjectURL(file)
				setImage(newFile)
				const el = document.getElementById("uploaded-image")
				if (el) {
					let croppieInstance = new Croppie(el, {
						viewport: { width: 150, height: 150 },
						boundary: { width: 154, height: 154 },
						showZoomer: true,
						enableOrientation: true
					});
					croppieInstance.bind({
						url: newFile,
					});
					setImage({croppie: true, url: newFile, type: fileType, name: fileName, file: croppieInstance})
				}
				return;
			}
			sel.classList.remove('hide')
			const newFile = window.URL.createObjectURL(file)
			setImage({croppie: false, url: newFile, type: fileType, name: fileName, file: file})
			return;
		}
		UIkit.modal.alert('Please upload an image file.')
		return;
	}

	const cancelSave = () => {
		destroyCroppie()
		setName('')
		setImage('')
	}
	
	const saveCompetitor = async () => {
		if (!name || !image) {
			UIkit.modal.alert('Please give this competitor a name and upload an icon.')
			return;
		}
		let fileToUpload
		if (image.croppie) {
			fileToUpload = await image.file.result({
				type:'blob',
				size: {'width': 256, 'height': 256}
			})
		} else {
			fileToUpload = image.file
		}
		const now = new Date().getTime();
		const iconRef = ref(storage, `uploaded-competitor-icons/${now}-${image.name}`)
		const metadata = {
			contentType: image.type,
		};
		uploadBytes(iconRef, fileToUpload, metadata).then((snapshot) => {
			getDownloadURL(iconRef)
			.then((url) => {
				finalizeSave(url,now)
				return;
			})
		})
	}

	const finalizeSave = async (url, now) => {
        const data = {
		    name: name,
    		id: name.replace(/[^a-zA-Z0-9]/g, '').toLowerCase() + '_comp',
    		icon : url
  		}
		const res = await UseCloudFunction('saveCompetitor', {'compData': data, 'gameId': currentGame.gameId})
		console.log(res);
        cancelSave()
	}
	
	return (
		<div className='new-competitor g-box'>
			<h4>Add a new competitor</h4><hr />
			<div className="uk-margin">
	            <label>Competitor Name</label>
				<input className="uk-input" type="text" onChange={e => setName(e.target.value)} value={name} />
	        </div>
			<div className="uk-margin">
				<div data-uk-form-custom >
					<input type="file" id="fileUpload" accept="image/*" onChange={(e) => handleUpload(e.target.files[0])}  />
					<button className="g-button" type="button" tabIndex="-1">Upload an Icon</button>
				</div>
			</div>
			<div className="uk-margin">
				<div id="selected-image">
					{ 
						(image) 
						? <img className="image" src={image.url || image} />
						: ''
					}
				</div>
				<div id="uploaded-image">
							
				</div>
			</div>
			<div className='new-competitor-buttons'>
				<button className='g-button' type='button' onClick={() => cancelSave()}>Cancel</button>
				<button className='g-button' type='button' style={{'backgroundColor':'#03C04A'}} onClick={() => saveCompetitor()}>Save</button>
			</div>
		</div>
	)
}

export default NewCompetitor