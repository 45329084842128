import React, { useState, useEffect } from 'react'
import './chat-display.styles.scss'

import { useSelector } from 'react-redux'

import useWindowResize from '@/utils/windowSize'
import ChatItem from '@/components/chat/chat-item.component'
import ChatView from '@/components/chat/chat-view.component'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

const ChatDisplay = ({ setReplyId, chats, leaders, unread }) => {

	const [ sortedChat, setSortedChat ] = useState(null)
	let [ width, height ] = useWindowResize();
	const [ chatToView, setChatToView ] = useState(null)
	const [ maxChats, setMaxChats ] = useState(5)

	useEffect(() => {
		if (chats) {
			const arr = Object.values(chats)
			arr.sort((a,b) => (a.ts < b.ts) ? 1 : -1)
			console.log('sorting chat')
			setSortedChat(arr)
            if (chatToView) {
                const id = chatToView.id
                setChatToView({...chats[id]})
            }
		}
	}, [chats])

    function goBack() {
		setChatToView(null)
		setReplyId(null)
	}
	
	return (
        <div>
            {
                chatToView &&
                <p className='chat-back-button' onClick={() => goBack()}><FontAwesomeIcon icon={faArrowLeft} /> Back</p>
            }
            <div className='chat-display' id='chat-display' style={{'height': height-361}}>
                {
                    (chatToView) 
                    ?
                    <ChatView mess={chatToView} setChatToView={setChatToView} setReplyId={setReplyId} leaders={leaders} unread={unread} />
                    
                    :
                    (sortedChat && leaders) 
                    ?
                    sortedChat.map((mess, index) => (
                        (index < maxChats) &&
                        <div key={mess.id}>
                            <ChatItem mess={mess} icon={leaders[mess.appId] ? leaders[mess.appId].icon : ''} setChatToView={setChatToView} setReplyId={setReplyId} replyButton={true} unread={unread} />
                        </div>
                    ))
                    :
                    'No messages yet...'
                }
                {
                    (sortedChat && !chatToView && sortedChat.length > maxChats) &&
                    <div className='more-button-div'>
                        <button className='g-button' onClick={() => setMaxChats(maxChats + 5)}>load more...</button>
                    </div>
                }
            </div>
        </div>
	)
}

export default ChatDisplay;