import React, { useEffect, useState } from 'react'
import './competitor-import.styles.scss'

import { useSelector, useDispatch } from 'react-redux'
import { setCompetitors } from '@/state/slices/gameSlice'
import { UseCloudFunction } from '@/firebase-utils/firebase.utils'

const CompetitorImport = ({ competitors }) => {

	const currentGame = useSelector(state => state.gameData.currentGame);
    const commishGames = useSelector(state => state.gameData.commishGames);
    const [ gamesToDisplay, setGamesToDisplay ] = useState(null);

    useEffect(() => {
        if (!commishGames) return;
        let newGamesList = {}
        for (let gm of Object.values(commishGames)) {
            newGamesList[gm.gameId] = gm
        }
        console.log(newGamesList);
        setGamesToDisplay({...newGamesList})
    }, [commishGames])

	async function importCompetitors() {
		const importId = document.getElementById('import-select').value
		const data = {
			'gameId': currentGame.gameId,
			'importId': importId,
		}
		console.log(data)
		const importResults = await UseCloudFunction('importCompetitors', data)
		console.log(importResults);
	}
	
	return (
		<div className='competitor-import'>
			<div className='g-box'>
				<h4>Import Competitors</h4><hr />
				<select className="uk-select" id="import-select" aria-label="Select">
					<option value=''>Choose a game</option>
					{
						(gamesToDisplay)
						?
						Object.values(gamesToDisplay)
                        .sort((a,b) => (a.ts > b.ts) ? 1 : -1)
                        .map(game => (
							(game.gameId !== currentGame.gameId)
							?
							<option key={game.gameId} value={game.gameId}>{game.title}</option>
							: ''
						))
						: ''
					}
				</select>
				<div className='buttons'>
					<button className='g-button' onClick={() => importCompetitors()}>Import</button>
				</div>
			</div>
		</div>
	)
}

export default CompetitorImport;