import React from 'react'

const getWagers = (wager, id, myPicks) => {

	let wagerHtml
	
	if (wager === 'wager1') {
		wagerHtml = 
			<>
				<option value="">Wager...</option>
				<option value="100">100</option>
				<option value="200">200</option>
				<option value="300">300</option>
				<option value="400">400</option>
				<option value="500">500</option>
			</>
	} 
	else if (wager === 'wager2') {
		wagerHtml = 
			<>	
				<option value="">Wager...</option>
				<option value="300">300</option>
				<option value="400">400</option>
				<option value="500">500</option>
				<option value="600">600</option>
				<option value="700">700</option>
			</>
	}
	else if (wager === 'wager3') {
		wagerHtml = 
			<>	
				<option value="">Wager...</option>
				<option value="500">500</option>
				<option value="600">600</option>
				<option value="700">700</option>
				<option value="800">800</option>
				<option value="900">900</option>
			</>
	}
	else if (wager === 'wager4') {
		wagerHtml = 
			<>	
				<option value="">Wager...</option>
				<option value="800">800</option>
				<option value="900">900</option>
				<option value="1000">1,000</option>
				<option value="1100">1,100</option>
				<option value="1200">1,200</option>
			</>
	}
	
	return wagerHtml
}

export default getWagers