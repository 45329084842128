import React, { useEffect, useState } from 'react'
import './mobile-header.styles.scss'

import { useSelector } from 'react-redux'
import UIkit from 'uikit'
import { useNavigate } from 'react-router-dom'
import { version } from '@/changelog-version'
import { sortLeaders } from '@/utils/sortLeaders'

const MobileHeader = ({ leaders }) => {

    const currentGame = useSelector(state => state.gameData.currentGame)
    const profile = useSelector(state => state.userData.profile)
    const [ sortedLeaders, setSortedLeaders ] = useState([]);
    const [ rank, setRank ] = useState(null);
    const [ pointsTotal, setPointsTotal ] = useState(0);
    const [ gameProfile, setGameProfile ] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (!leaders) return;
        setSortedLeaders(sortLeaders(leaders));
    }, [leaders])

    useEffect(() => {
        if (!sortedLeaders || !profile) return;
        setRank(`${sortedLeaders.findIndex(leader => leader.appId === profile.appId) + 1} / ${sortedLeaders.length}`);
        const leader = sortedLeaders.filter(l => l.appId === profile.appId)[0];
        setGameProfile(leader)
        if (leader) {
            const points = (leader.matchPoints + leader.propPoints + leader.bonusPoints).toLocaleString()
            setPointsTotal(points);
        }
    }, [sortedLeaders, profile])

    function goTo(page) {
		const el = document.getElementById('user-dropdown')
		UIkit.dropdown(el).hide();
		navigate(`${page}`)
	}

    return (
        <div>
            <div className='mobile-header'>
                <table className='full'>
                    <tbody>
                        <tr>
                            <td height="85px" className='image-td'>
                                {
                                    (currentGame) 
                                    ? <img src={currentGame.banner} className='mobile-header-img' />
                                    : ''
                                }
                            </td>
                        </tr>

                    </tbody>
                </table>
                <table className='full'>
                    <tbody>
                        <tr>
                            <td className='mobile-header-td' width="50%">
                                <span>Rank: 
                                    {
                                        (sortedLeaders.length > 0)
                                        ? ` ${rank}`
                                        : ``
                                    }
                                </span>
                            </td>
                            <td className='mobile-header-td right'>
                                <span className='points'>{pointsTotal} pts</span>
                                {
                                    (gameProfile) 
                                    ? 
                                    <div className="uk-inline">
                                        <img src={(gameProfile.icon.url) ? gameProfile.icon.url : gameProfile.icon} className='user-icon' />
                                        <div data-uk-dropdown="mode: click" id='user-dropdown'>
                                            <a className='uk-link-reset' onClick={() => goTo('profile')}>
                                                My Game Profile
                                            </a><br /><br />
                                            <a className='uk-link-reset' onClick={() => goTo('info')}>
                                                Game Information
                                            </a><br /><br />
                                            <a className='uk-link-reset' href='https://poolparty.tamingthetech.com/changelog.txt' target='_blank'>
                                                Change Log
                                            </a>
                                            <p className='meta'>{version}</p>
                                        </div>
                                    </div>
                                    : ''
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        <div style={{'height':'160px'}} className='' />
        </div>
    )
}

export default MobileHeader