import { createSlice } from '@reduxjs/toolkit'
import { revertAll } from './userSlice'

const initialState = {
    archivedCommishGames: null,
    archivedMyGames: null,
    challenges: null,
    chat: null,
    commishGames: null,
    competitors: null,
    currentGame: null,
    leaders: null,
    myGames: null,
    myPicks: null,
    nextMatchup: null,
    notifications: null,
    picks: null,
    unread: null,
    hofData: null,
}

export const gameSlice = createSlice({
    name: 'game',
    initialState,
    extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
    reducers: {
        setArchivedCommishGames: (state, action) => {
            state.archivedCommishGames = action.payload
        },
        setArchivedMyGames: (state, action) => {
            state.archivedMyGames = action.payload
        },
        setChallenges: (state, action) => {
            state.challenges = action.payload
        },
        setChat: (state, action) => {
            state.chat = action.payload
        },
        setCommishGames: (state, action) => {
            state.commishGames = action.payload
        },
        setCompetitors: (state, action) => {
            state.competitors = action.payload
        },
        setCurrentGame: (state, action) => {
            state.currentGame = action.payload
        },
        setLeaders: (state, action) => {
            state.leaders = action.payload
        },
        setMyGames: (state, action) => {
            state.myGames = action.payload
        },
        setMyPicks: (state, action) => {
            state.myPicks = action.payload
        },
        setNextMatchup: (state, action) => {
            state.nextMatchup = action.payload
        },
        setNotifications: (state, action) => {
            state.notifications = action.payload
        },
        setPicks: (state, action) => {
            state.picks = action.payload
        },
        setUnread: (state, action) => {
            state.unread = action.payload
        },
        setHofData: (state, action) => {
            state.hofData = action.payload
        },
        resetGameData: (state) => (state = initialState),
    },
})

// Action creators are generated for each case reducer function
export const { 
    setArchivedCommishGames,
    setArchivedMyGames,
    setChallenges,
    setChat,
    setCommishGames,
    setCompetitors,
    setCurrentGame,
    setLeaders,
    setMyGames,
    setMyPicks,
    setNextMatchup,
    setNotifications,
    setPicks,
    setUnread,
    setHofData,
    resetGameData
} = gameSlice.actions

export default gameSlice.reducer