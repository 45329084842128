import React, {useEffect, useState} from 'react'
import './side-nav.styles.scss'

import { Link, useNavigate } from 'react-router-dom'
import { signOut } from '@/firebase-utils/firebase.utils'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse, faUser, faPlus, faRightFromBracket, faDoorOpen, faComments, faBell, faInfo, faInfoCircle, faTrophy } from '@fortawesome/free-solid-svg-icons'
import icon from '@/assets/icon.png'

const SideNav2 = ({ unread }) => {

	const navigate = useNavigate()
	const currentURL = window.location.href
	const currentGame = useSelector(state => state.gameData.currentGame)
    const [ unreadChats, setUnreadChats ] = useState(0);
    const [ unreadNotifications, setUnreadNotifications ] = useState(0);
    
    useEffect(() => {
        if (!unread) return;
        setUnreadChats(Object.values(unread).filter(u => u.type === 'chat').length)
        setUnreadNotifications(Object.values(unread).filter(u => u.type === 'general').length);
    }, [unread])

    const goHome = () => {
		navigate('/home')
	}
	
	return (
		<div className='sidenav uk-visible@m'>
			<div className='top'>
				<img className='hide nav-icon' src={icon} alt='poolparty icon' />
			</div>
			<div className='bottom'>
				
				{
					(currentGame)
					?
					<>
						<Link to={(currentURL.includes('/game/')) ? '/game' : '/home'}>
							<FontAwesomeIcon className='link' icon={(window.location.pathname === '/game') ? faDoorOpen : faHouse} />
						</Link>
                        {
                            !currentURL.includes('admin') &&
                            <>
                                <Link to='info'>
                                    <FontAwesomeIcon className='link' icon={faInfo} title='Game Information' />
                                </Link>
                                <Link to="chat" className='chat-leader-icon'>
                                    <div className="notification-with-badge">
                                        <FontAwesomeIcon className="link" icon={faComments} title='Game Chat' />
                                        {
                                            (unreadChats > 0) && 
                                            <span className="badge">{unreadChats}</span>
                                        }
                                    </div>
                                </Link>
                                <Link to="leaders" className='chat-leader-icon'>
                                    <FontAwesomeIcon className="link" icon={faTrophy} title='Leaderboard' />
                                </Link>
                                <Link to="notifications">
                                    <div className="notification-with-badge">
                                        <FontAwesomeIcon className="link" icon={faBell} title='Notifications' />
                                        {
                                            (unreadNotifications > 0) && 
                                            <span className="badge">{unreadNotifications}</span>
                                        }
                                    </div>
                                </Link>
                                
                            
                                <Link to="profile">
                                    <FontAwesomeIcon className="link" icon={faUser} title='My Game Profile' />
                                </Link>
                            </>
                        }
					</>
					:
					<>
						<FontAwesomeIcon className="link" id="door" icon={faHouse} title="Home" onClick={() => goHome()}/>
						<Link to="/profile">
							<FontAwesomeIcon className="link" icon={faUser} title='My Profile' />
						</Link>
					</>
				}
				{
					(!currentGame) ?
					<Link to="/home/creategame">
						<FontAwesomeIcon className="link" icon={faPlus} title="Create New Game" />
					</Link>
					: ''
				}
				<Link to="/logout">
					<FontAwesomeIcon className="link" icon={faRightFromBracket} title="Sign Out" />
				</Link>
			</div>
		</div>
	)
}

export default SideNav2;