import React, { useEffect, useRef, useState } from 'react';
import './prop-pick-modal.styles.scss';
import Modal from '@/components/modal/modal.component';
import { format } from 'date-fns';
import getWagers from '@/utils/wager.utils';
import question_mark from '@/assets/questionmark.png';
import streak_bonus from '@/assets/streak-bonus.png';
import { useSelector } from 'react-redux';
import { UseCloudFunction } from '@/firebase-utils/firebase.utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import check_box  from '@/assets/check-box.png';
import crossed from '@/assets/crossed.png'
import ThreeDotsLoader from '@/components/loader/three-dots-loader.component';
import { toast } from 'react-toastify';
import { properCase } from '@/utils/properCase';
import UIkit from 'uikit';

const PickModal = ({ closeModal, prop }) => {

    const userProfile = useSelector(state => state.userData.profile);
    const currentGame = useSelector(state => state.gameData.currentGame);
    const [ pick, setPick ] = useState({});
    const [ pickedWager, setPickedWager ] = useState('');
    const [ loading, setLoading ] = useState(false);
    const [ formData, setFormData ] = useState({});
    const [ comp1Total, setComp1Total ] = useState(0);
    const [ comp2Total, setComp2Total ] = useState(0);
    const [ pick1Bg, setPick1Bg ] = useState('');
    const [ pick2Bg, setPick2Bg ] = useState('');
    const dataRef = useRef();

    useEffect(() => {
        if (!prop || !prop.picks || !userProfile) return;
        if (prop.picks[userProfile.appId]) {
            setPick(prop.picks[userProfile.appId]);
            setPickedWager(prop.picks[userProfile.appId].wager);
            dataRef.current = {
                ...dataRef.current, 
                ...{
                    'wager': prop.picks[userProfile.appId].wager,
                    'pick': prop.picks[userProfile.appId].pick,
                }
            }
            setFormData(dataRef.current);
        }

        const c1 = (Object.values(prop.picks).filter(p => p.pick === 'comp1').reduce((acc, p) => { return acc + p.wager }, 0))
        setComp1Total(formatNumber(c1)); 
        const c2 = (Object.values(prop.picks).filter(p => p.pick === 'comp2').reduce((acc, p) => { return acc + p.wager }, 0))
        setComp2Total(formatNumber(c2));

        function formatNumber(number) {
            // Define the suffixes for thousand, million, billion, etc.
            const suffixes = ['', 'k', 'M', 'B', 'T'];
            // Divide the number by 1000 until it's less than 1000 and find the appropriate suffix
            let suffixIndex = 0;
            while (number >= 1000 && suffixIndex < suffixes.length - 1) {
                number /= 1000;
                suffixIndex++;
            }
            // Round the number to 1 decimal place
            number = Math.round(number * 10) / 10;
            // Append the suffix
            const formattedNumber = number.toString() + suffixes[suffixIndex];
            return formattedNumber;
        }

        return () => {
            setPick1Bg('');
            setPick2Bg('');
        }

    }, [prop, userProfile])

    useEffect(() => {
        if (!pick || !pick.pick || !prop) return;
        if (prop.winner) {
            if (pick.pick === prop.winner) {
                if (prop.winner === 'comp1') {
                    setPick1Bg('picked-correct');
                    setPick2Bg('');
                } else if (prop.winner === 'comp2') {
                    setPick1Bg('');
                    setPick2Bg('picked-correct');
                }
            } else if (pick.pick) {
                if (prop.winner === 'comp1') {
                    setPick1Bg('');
                    setPick2Bg('picked-wrong');
                } else if (prop.winner === 'comp2') {
                    setPick1Bg('picked-wrong');
                    setPick2Bg('');
                }
            }
        } else if (pick.pick === 'comp1') {
            setPick1Bg('picked');
            setPick2Bg('');
        } else if (pick.pick === 'comp2'){
            setPick1Bg('');
            setPick2Bg('picked');
        }
    }, [pick, prop])

    function cancelPick() {
        dataRef.current = {...{}}
        setFormData(dataRef.current);
        setPick({...{}});
        closeModal();
        setComp1Total(0);
        setComp2Total(0);
        setLoading(false);
        setPickedWager('');
    }

    function saveData(key, value) {
        if (key === 'pick' && (!dataRef.current || !dataRef.current.wager)) {
            UIkit.modal.alert('You must make a wager before picking a winner.');
            return;
        }
        if (key === 'wager' && !value) {
            UIkit.modal.alert('You must make a wager before picking a winner.');
            return;
        }
        dataRef.current = {...dataRef.current, ...{[key]: value}}
        setFormData(dataRef.current);
        if (key === 'pick') {
            savePick('pick');
        }
        if (key === 'wager') {
            setPickedWager(value);
            if (dataRef.current.pick) {
                savePick('wager');
            }
        }
    }   

    async function savePick(pickWager) {
        
        if (pickWager === 'pick') {
            setLoading(true);
        }
        const res = await UseCloudFunction(
            'savePick', 
            {
                'gameId': currentGame.gameId,
                'matchupId': prop.id,
                'pick': dataRef.current.pick,
                'wager': dataRef.current.wager,
                'appId': userProfile.appId,
                'ts': new Date().getTime(),
            }
        )
        console.log(res);
        toast(`${properCase(pickWager)} saved successfully!`);
        if (pickWager === 'pick') {
            cancelPick();
        }
    }

    return (
        <div className='pick-modal'>
            <Modal handleClose={cancelPick} cancel={cancelPick} show={prop} buttonText={'Save Wager'} >
                {
                    (prop) &&
                    <div className='modal-content'>
                        <div className='title'>{prop.name}</div>
                        <div className='desc'>{prop.desc}</div>
                        <div className="g-space-0-5"></div>
                        {
                            (new Date().getTime() < prop.date) 
                            ?
                            <div>Locks: {format(new Date(prop.date), "E, Pp")}</div>
                            :
                            <div className='center-col'><FontAwesomeIcon icon={faLock} /></div>
                        } 
                        <div className="g-space-0-5"></div>
                        {
                            ((new Date().getTime() < prop.date) && !prop.winner)
                            ? 
                            <div className='field'>
                                <label><b>Pick a Wager</b></label><br/>
                                <select 
                                    className="uk-select uk-form-small uk-form-width-small wager" 
                                    value={pickedWager}
                                    onChange={e => saveData('wager', Number(e.target.value))}  >	
                                {getWagers(prop.wager, prop.id)}
                                </select>
                            </div>
                            : 
                            (prop.winner && pick.pick)
                            ?
                            <div>
                                <b>Result: {pick.pick === prop.winner ? <span className='win'>+{pick.wager}</span> : <span className='loss'>-{pick.wager}</span>}</b>
                            </div>
                            :
                            <div>
                                {
                                    (pick.wager) &&
                                    <b>Wager: {pick.wager}</b>
                                }
                                
                            </div>
                        }
                        <div className="g-space-1"></div>
                        <div className='icons-div'>
							<div className={`modal-img-div ${pick1Bg}`}>
								<div >{prop.comp1}</div>
                                {
                                    (loading)
                                    ?
                                    <button className='g-button' ><ThreeDotsLoader /></button>
                                    :
                                    (new Date().getTime() < prop.date && prop.comp1)
                                    ? <button className='g-button' onClick={() => saveData('pick','comp1')}>Pick</button>
                                    : ''
                                }
                                {
                                    (prop.winner === 'comp1' && !pick.pick) 
                                    ?
                                    <>
                                        <div className="g-space-0-5"></div>
                                        <img className='check' src={check_box} />
                                    </>
                                    :
                                    (prop.winner === 'comp2' && !pick.pick) 
                                    ?
                                    <>
                                        <div className="g-space-0-5"></div>
                                        <img className='crossed' src={crossed} />
                                    </>
                                    :
                                    ''
                                }
							</div>
							
							<div className={`modal-img-div ${pick2Bg}`}>
								<div>{prop.comp2}</div>
                                {
                                    (loading)
                                    ?
                                    <button className='g-button' ><ThreeDotsLoader /></button>
                                    :
                                    (new Date().getTime() < prop.date && prop.comp1)
                                    ? <button className='g-button' onClick={() => saveData('pick','comp2')}>Pick</button>
                                    : ''
                                }
                                {
                                    (prop.winner === 'comp2' && !pick.pick) 
                                    ?
                                    <>
                                        <div className="g-space-0-5"></div>
                                        <img className='check' src={check_box} />
                                    </>
                                    :
                                    (prop.winner === 'comp1' && !pick.pick) 
                                    ?
                                    <>
                                        <div className="g-space-0-5"></div>
                                        <img className='crossed' src={crossed} />
                                    </>
                                    :
                                    ''
                                }
							</div>
                            <div className={`meta`}>
                                Tot Wagers: {comp1Total}
                            </div>
                            <div className={`meta`}>
                                Tot Wagers: {comp2Total}
                            </div>
						</div>
                        {
                            (prop.comp1Details) &&
                            <>
                                <div className='g-space-0-5'></div>
                                <div className='comp-details'>{prop.comp1Details}</div>
                            </>
                        }
                    
                    </div>
                }
            </Modal>
        </div>
    )
}

export default PickModal