import React, { useEffect, useState, useRef } from 'react'
import './challenge-list.styles.scss'

import { useSelector } from 'react-redux'
import { format } from 'date-fns'

import Modal from '@/components/modal/modal.component'
import FileUpload from './file-upload.component'
import UIkit from 'uikit'
import { UseCloudFunction } from '@/firebase-utils/firebase.utils'

import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'

const ChallengeList = ({ setChallenge }) => {

	const challenges = useSelector(state => state.gameData.challenges)
	const currentGame = useSelector(state => state.gameData.currentGame)
	const gameProfile = useSelector(state => state.userData.profile)
	const [ challengeList, setChallengeList ] = useState(null)
	const currentURL = window.location.href
	const [ uploadedFile, setUploadedFile ] = useState(null)
	const [ textInput, setTextInput ] = useState('')
	const [ showModal, setShowModal ] = useState(false)
	const [ isAdmin ] = useState(currentURL.includes('/gameadmin'))
	const [ modalChildren, setModalChildren ] = useState('')
	const storage = getStorage();
	const stateRef = useRef();
	stateRef.current = uploadedFile;
	
	useEffect(() => {
		if (challenges) {	
			const list = Object.values(challenges).sort((a,b) => (a.date > b.date) ? 1 : -1)
			setChallengeList(list)
		}
	}, [challenges])

	const openModal = (id) => {
		console.log(id)
		const c = challenges[id]
		const children = 
			<div>
				<table className='modal-table'>
					<tbody>
						<tr>
							<td className='modal-text-td'>
								<img src={c.icon} className='modal-list-icon' /><br />
								<b>{c.name}</b>
								<br />
								{c.dir}
								{
									(!isAdmin && c.upload) 
									? 
									<div>
										<FileUpload setUploadedFile={setUploadedFile} />
										<div className='uk-margin'>
											<textarea className='uk-textarea' type='text' rows='2' placeholder='Include an explanation here...' defaultValue={(textInput) ? textInput : ''} onChange={(e) => setTextInput(e.target.value)}></textarea>
										</div>
										<div className='uk-margin'>
											<button className='g-button green' onClick={() => {submitForm(c.id)}}>Submit</button>
										</div>
									</div>
									: ''
								}
							</td>
						</tr>
						<tr>
							
						</tr>
					</tbody>
				</table>
			</div>
		setModalChildren(children)
		setShowModal(true)
		
	}

	const closeModal = () => {
		setShowModal(false)
		const els = document.getElementsByClassName('spinner')
		for (let el of els) {
			el.classList.add('hide')
		}
	}

	const editChallenge = (id) => {
		// console.log(id)
		document.getElementById('new-challenge-div').scrollIntoView({'behavior':'smooth'})
		setChallenge(challenges[id])
	}

	async function submitForm(id) {
		let approved = false
		console.log(uploadedFile)
		console.log(stateRef.current.name)
		if (challenges[id].upload && !stateRef.current) {
			UIkit.modal.alert('Please upload a file before submitting.')
			return;
		}
		await UIkit.modal.confirm('Are you sure you want to submit this content?').then(() => {
			console.log('approved')
			approved = true
		}, () => {
			console.log('rejected')
		})
		if (!approved) {
			console.log('not approved')
			return;
		} else {
			console.log('yes approved')
		}
		const now = new Date().getTime();
		const fileRef = ref(storage, `uploaded-challenge-files/${now}-${stateRef.current.name}`)
		const metadata = {
			contentType: stateRef.current.type,
		};
		uploadBytes(fileRef, stateRef.current, metadata).then((snapshot) => {
			getDownloadURL(fileRef)
			.then((url) => {
				console.log(url)
				finalizeSave(id,url,now)
				return;
			}).catch(err => console.log(err))
		}).catch(err => console.log(err))
	}

	async function finalizeSave(id, url, now) {
		const data = {
		    'gameId': currentGame.gameId,
			'challengeId': id,
			'profile': gameProfile,
			'submission': {
				'text': textInput,
				'url': url
			},
			'ts': now
  		}
		console.log(data)
		const resp = await UseCloudFunction('submitChallenge', data)
		console.log(resp)
	}

	
	return (
		<div className='challenge-list'>
			{
				(challengeList) 
				?
				challengeList.map(chall => (
					<div key={chall.id} className={(!isAdmin) ? 'matchup g-box clickable': 'matchup g-box'} onClick={
						(!isAdmin)
						? () => openModal(chall.id) 
						: () => {}
					}>
						<table>
							<tbody>
								<tr>
									<td width="80px">
										<img src={chall.icon} className='list-icon' />
									</td>
									<td className='challenge-text-td'>
										<p><b>{chall.name}</b><br />
										
										{chall.dir}</p>
										
										{
											(chall.upload && isAdmin) ? <>Requires Upload<br/></> : ''
										}
										{
											(chall.approval && isAdmin) ? <>Requires Approval<br /></> : ''
										}
										
										{
											(isAdmin)
											?
											<p>Opens {format(new Date(chall.date), "EEE, L/d/y h:mm bbb")}</p>
											: ''
										}
									</td>
								</tr>
							</tbody>
						</table>
						{
							(window.location.href.includes('/gameadmin/')) 
							? <div className='admin-buttons'>
								<a className="uk-icon-button uk-margin-small-right" data-uk-icon="pencil" onClick={() => editChallenge(chall.id)}></a>
								<a href="" className="uk-icon-button uk-margin-small-right" data-uk-icon="trash"></a>
							</div>
							: ''
						}
					</div>
				))
				: <h3>No challenges created...</h3>
			}
			<Modal handleClose={closeModal} cancel={closeModal} show={showModal} children={modalChildren} buttonText={'Save Wager'} />
		</div>
	)
}

export default ChallengeList;