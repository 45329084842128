import React from 'react'
import './sliding-menu.styles.scss'
import { useNavigate } from 'react-router-dom'

import { signOut } from '../../firebase-utils/firebase.utils'

const SlidingMenu = () => {

	const navigate = useNavigate()
	
	const navSignOut = async () => {
		await signOut()
		console.log('signing out')
		navigate('/')
	}
	
	return (
		<div id="sliding-menu" data-uk-offcanvas>
			<div className="uk-offcanvas-bar menuDiv">
				<button className="uk-offcanvas-close" type="button" data-uk-close></button>
				<div id="mUserInfo">
				</div>
				<h3>Menu</h3>
				
				<p><a className="uk-link-reset" onClick={() => navigate('/logout')}>
					<i className="home icon" ></i>Sign Out
				</a></p>
				
				<hr />

				<p><a href="mailto:adam@gamably.org" target="_blank" className="uk-link-reset">
					<i data-uk-icon="question"></i>Ask Adam a Question
				</a></p>
				<p>Copyright © 2018-2020
					<br />
					Taming The Tech, LLC
					<br />
					<a href="https://blog.tamingthetech.org/" target="_blank" className="footer-link">
						About
					</a> | 
					<a href="mailto:adam@tamingthetech.com" target="_blank" className="footer-link">
						Contact
					</a><br />
					<a href="/info/terms.html" target="_blank" className="footer-link">
						Terms of Use
					</a> | 
					<a href="/info/privacy.html" target="_blank" className="footer-link">
						Privacy Policy
					</a>
				</p>
				
			</div>
		</div>
	)
}

export default SlidingMenu