import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import './sign-in.styles.scss'
import './google-btn.styles.scss'
import { auth } from '../../firebase-utils/firebase.utils'
import { signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from 'firebase/auth'
import { setCurrentUser } from '@/state/slices/userSlice'
import { useDispatch } from 'react-redux'
import banner_no_logo from '@/assets/banner-no-logo.png';

const SignIn = () => {

	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const provider = new GoogleAuthProvider();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        const currentUser = auth.currentUser
        console.log(currentUser)
        if (currentUser) {
            const newUser = {
                displayName: currentUser.displayName,
                email: currentUser.email,
                metadata: {
                createdAt: Number(currentUser.metadata.createdAt),
                lastLogInAt: currentUser.metadata.lastLogInAt
                },
                uid: currentUser.uid,
                photoURL: currentUser.photoURL,
                provider: currentUser.providerData[0].providerId
            }
            dispatch(setCurrentUser({...newUser}));
            navigate('/loading')
        }
    }, [])
	
	const handleSubmit = async (e) => {
		e.preventDefault();		
		signInWithEmailAndPassword(auth, email, password)
		.then((userCredential) => {
			// Signed in 
			const user = userCredential.user;
			const currentURL = window.location.pathname
            console.log(user)
			const newUser = {
                displayName: user.displayName,
                email: user.email,
                metadata: {
                createdAt: Number(user.metadata.createdAt),
                lastLogInAt: user.metadata.lastLogInAt
                },
                uid: user.uid,
                photoURL: user.photoURL,
                provider: user.providerData[0].providerId
            }
            dispatch(setCurrentUser({...newUser}));
            if (currentURL.includes('/signin')) {
				// navigate('/home')
                navigate('/loading');
			}
			// ...
		}).catch(err => console.log(err))
		
		setEmail('')
		setPassword('')
	}

	
	const signInWithGoogle = () => {
		console.log('signing in')
		const currentURL = window.location.href
		signInWithPopup(auth, provider)
		.then((result) => {
			if (currentURL.includes('/signin')) {
				navigate('/loading')
			}
		}).catch(err => console.log(err))
		return;
	}

	return (
		<div className='sign-in'>
			<img className='img' src={banner_no_logo} />
			<div className="g-box sign-in-box">
				<h3>Sign In</h3><hr />
				<button onClick={signInWithGoogle} className="login-with-google-btn" >
					Sign in with Google
				</button>
				<p>- OR -</p>
				<span>Sign in with your email and password</span>
			
				<form className="uk-margin" onSubmit={handleSubmit}>
					<div className="uk-margin">
						<input className="uk-input" type="text" placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
					</div>
					<div className="uk-margin">
						<input className="uk-input" type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
					</div>
					

					<div className='buttons'>
						<Link to='/logout'>
							<button className='g-button'>
								Cancel
							</button>
						</Link>
						<button className='g-button' type="submit">
							Sign In
						</button>
						
						{/* <CustomButton onClick={signInWithGoogle} isGoogleSignIn>
							Sign In With Google
						</CustomButton>*/} 
					</div>
				</form>
				<h5>Don't have an account? Register <Link to='/register'>HERE</Link></h5>
			</div>
		</div>
	)
}

export default SignIn