import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import './landing-content.styles.scss'
import { useDispatch, useSelector } from 'react-redux'
import { getAuth } from 'firebase/auth'
import { setCurrentUser } from '@/state/slices/userSlice'
import logo_white from '@/assets/logo-white.png';
import ThreeDotsLoader from '../loader/three-dots-loader.component'

const LandingContent = () => {

    const auth = getAuth();
	const [ user ] = useState(auth.currentUser);
    const [ showLogin, setShowLogin ] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            if (!user) {
                setShowLogin(true);
            }
        }, 2000)
    }, [])

    useEffect(() => {
        if (user) {
            const newUser = {
                displayName: user.displayName,
                email: user.email,
                metadata: {
                    createdAt: Number(user.metadata.createdAt),
                    lastLogInAt: user.metadata.lastLogInAt
                },
                uid: user.uid,
                photoURL: user.photoURL,
                provider: user.providerData[0].providerId
            }
            dispatch(setCurrentUser({...newUser}));
            navigate('/loading')
        }
    }, [user])


	return (
		<div className='landing-content'>
            <div>
                <div className='logo-div'>
                    <img className='logo' src={logo_white} alt='PoolParty logo' />
                </div>
                {
                    (showLogin)
                    ?
                    <Link to='/signin'>
                        <button className="uk-button uk-button-default landing-button">Sign In</button>
                    </Link>
                    : 
                    <div className='center-col'><ThreeDotsLoader /></div>
                }
            </div>
		</div>
	)
}

export default LandingContent