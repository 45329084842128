import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import './profile-icon.styles.scss'

// import IconSlider from './icon-slider/icon-slider.component'
import IconModal from './icon-modal.component'

import "croppie/croppie.css"
import Croppie from "croppie"
import placeholder_icon from '@/assets/placeholder-icon.jpg'
import UIkit from 'uikit'

const ProfileIcons = ({ icon, setIcon }) => {
	
    const currentUser = useSelector(state => state.userData.currentUser);
	
	const [ showIconModal, setShowIconModal ] = useState(false) 
	const [ croppedImage, setCroppedImage ] = useState('')
	const [ croppie, setCroppie ] = useState(null)
    let croppieInstance

	function hideModal() {
		setShowIconModal(false)
	}
	
	const chooseGoogleIcon = async () => {
		setCroppedImage('')
		document.getElementById('selected-image').classList.remove('hide')
		destroyCroppie()
		setIcon({upload: false, url: currentUser.photoURL})
		console.log('choose Google Icon')
	}

	const destroyCroppie = () => {
		const el = document.getElementById("uploaded-image")
		el.innerHTML = ''
		el.classList.remove("croppie-container");
	}

	// const getIcons = async () => {
	// 	setProfileIcon('')
	// 	destroyCroppie()
	// 	document.getElementById('selected-image').classList.remove('hide')
	// 	console.log('launch icon modal')
	// 	const modal = document.getElementById('chooseIconModal')
	// 	UIkit.modal(modal).show();
	// 	if (profileIcon.length > 0) {
	// 		UIkit.modal(modal).show();
	// 	} else {
	// 		const imagesRef = ref(storage, 'profileIcons');
	// 		var count = 0;
	// 		let iconList = []
	// 		listAll(imagesRef)
	// 		.then(res => {
	// 			res.items.forEach(itemRef => {
	// 				getDownloadURL(itemRef)
	// 				.then(url => {
	// 					iconList.push({url: url, id: count})
	// 					setProfileIcons(iconList.slice())
	// 					count++
	// 					console.log(JSON.stringify(iconList))
	// 				})
	// 			});
	// 		})
	// 		.then(function() {
	// 			console.log(JSON.stringify(iconList))
	// 			UIkit.modal(modal).show();
	// 		})
	// 		.catch(function(err) {
	// 			console.log(err.stack);
	// 		});
	// 	}
	// } 

	

	function handleUpload(file) {
		document.getElementById('selected-image').classList.add('hide')
		destroyCroppie()
		console.log(file.type)
		if (file.type.match(/image.*/)) {
			const fileType = file.type;
			const fileName = file.name;
			console.log('An image has been loaded');
			console.log(fileType)
			// Load the image
			if (fileType.indexOf('gif') == -1) {
				const newFile = window.URL.createObjectURL(file)
				// setCroppedImage(newFile)
				const el = document.getElementById("uploaded-image")
				if (el) {
					croppieInstance = new Croppie(el, {
						viewport: { width: 150, height: 150 },
						boundary: { width: 150, height: 150 },
						showZoomer: true,
						enableOrientation: true
					});
					croppieInstance.bind({
						url: newFile,
					});
					setCroppie(croppieInstance)
				}
				setCroppedImage(newFile)
				setIcon({upload: true, croppie: croppieInstance, type: fileType, name: fileName})
				return;
			}
			document.getElementById('selected-image').classList.remove('hide')
			const newFile = window.URL.createObjectURL(file)
			setIcon({upload: true, url: newFile, type: fileType, name: fileName, file: file})
			return;
		}
		UIkit.modal.alert('Please upload an image file.')
		return;
	}

	// function handleSubmit(e) {
	// 	e.preventDefault()
	// 	if (croppie !== null) {
	// 		croppie.result({
	// 			type: 'base64',
	// 			size: {
	// 				width: 150,
	// 				height: 150
	// 			}
	// 		})
	// 		.then((blob) => {
	// 			console.log(blob)
	// 		})
	// 	}
	// }

	return (
		<div>
			<div className="uk-grid-small uk-margin-top" data-uk-grid>
				<div className="uk-width-1-2@s">
					<div className="uk-margin">
						<label>You need an icon...</label>
					</div>
					<div className="image-buttons">
						<div className="uk-margin">
							{
								(currentUser.provider == 'google.com') 
								? <button className="g-button" type="button" onClick={() => chooseGoogleIcon()}>Use Your Google Icon</button>
								: ''
							}
						</div>
						<div className="uk-margin">
							<button className="g-button" type="button" onClick={() => setShowIconModal(true)}>Choose an Icon</button>
						</div>
						<div className="uk-margin">
							<div data-uk-form-custom style={{cursor: 'pointer'}}>
								<input type="file" id="fileUpload" onChange={(e) => handleUpload(e.target.files[0])}  />
								<button className="g-button" type="button" tabIndex="-1">Upload an Icon</button>
							</div>
						</div>
					</div>
				</div>
				
				<div className="uk-width-1-2@s">
					<div className="image-div">
						<div id="uploadDiv">
							<div id="selected-image">
								{ 
									(icon) 
										? <img className="profile-image" referrerPolicy="no-referrer" src={icon.url || icon} />
										: <img className="profile-image" referrerPolicy="no-referrer" src={placeholder_icon} />
								}
							</div>
							<div id="uploaded-image">
							
							</div>
							<a className="rotateButton" id="rotateButton"><i className="large redo icon"></i></a>
						</div>
					</div>
				</div>
			</div>
			<IconModal handleClose={hideModal} show={showIconModal} setIcon={setIcon} />
		</div>
	)
}

export default ProfileIcons


//	// profileIcons.map(img => (
									// 	<img key={img.id} className='profile-icons' value={img.url} onClick={(e) => chooseIcon(e.target.value)} src={img.url} />
									// ))
								