export function sortLeaders(leaders) {
    const leadersArr = Object.values(leaders).sort((a,b) => {
        const aTotal = a.matchPoints + a.propPoints + a.bonusPoints;
        const bTotal = b.matchPoints + b.propPoints + b.bonusPoints;
        if (aTotal < bTotal) {
            return 1;
        } else if (aTotal === bTotal) {
            const aCorrect = a.matchCorrect + a.propCorrect;
            const bCorrect = b.matchCorrect + b.propCorrect;
            if (aCorrect < bCorrect ) {
                return 1
            }
        }
        return -1
    })

    return leadersArr;
}