import React from 'react'
import './game-info.styles.scss'

import { useState } from 'react'
import { useSelector } from 'react-redux'
import { faInfo } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Linkify from 'linkify-react'

const GameInfo = () => {

    const currentGame = useSelector(state => state.gameData.currentGame)
    const [ linkifyOptions ] = useState({
        "truncate": 20,
        "target": "_blank",
    })

    return (
        <div className='game-info'>
            <div className="g-box head-box">
                <span className='main'>
                    <FontAwesomeIcon icon={faInfo} className='head-icon' />
                    Game Information
                </span>
                <hr />
                {
                    currentGame &&
                    <div><Linkify as="span" options={linkifyOptions}>{currentGame.desc}</Linkify></div>
                }
            </div>
        </div>
    )
}

export default GameInfo