import React from 'react';
import './admin-matchup-item.styles.scss';

import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';

const AdminMatchupItem = ({ matchup, editMatchup, deleteMatchup }) => {
    return (
        <div className='g-box admin-matchup'>
            <img src={matchup.comp1Icon} className='admin-matchup-icon' />
            <div className='desc-div'>
                <div className='desc-title'>{matchup.name}</div>
                <div className='details'>
                    <div>
                        {`${matchup.comp1Rank ? `#${matchup.comp1Rank}` + ' ' : ''}${matchup.comp1}`}<br />
                        {matchup.comp1Info ?? ''}
                    </div>
                    <div>vs</div>
                    <div>
                        {`${matchup.comp2Rank ? `#${matchup.comp2Rank}` + ' ' : ''}${matchup.comp2}`}<br />
                        {matchup.comp2Info ?? ''}
                    </div>
                </div>
                <div className="g-space-0-5"></div>
                <div className='desc-date'>
                    {format(new Date(matchup.date), "E, Pp")}
                </div>
                <div className="g-space-0-5"></div>
                <div className='buttons'>
                    <button className='g-button small-btn' onClick={() => editMatchup(matchup)}><FontAwesomeIcon icon={faPen} /></button>
                    <button className='g-button small-btn' onClick={() => deleteMatchup(matchup)}><FontAwesomeIcon icon={faTrash} /></button>
                </div>
            </div>
            <img src={matchup.comp2Icon} className='admin-matchup-icon' />
            
        </div>
    )
}

export default AdminMatchupItem