import React, { useState } from 'react'
import './competitors.styles.scss'

import { Link } from 'react-router-dom'
import NewCompetitor from '../new-competitor/new-competitor.component'
import CompetitorList from './competitor-list/competitor-list.component'
import CompetitorImport from './competitor-import/competitor-import.component'

const Competitors = ({ competitors }) => {

	const [ showImport, setShowImport ] = useState(false)
	
	return (
		<div className='competitors'>
            <div className='buttons'>
                <Link to='/gameadmin'>
                    <button className='g-button'>Back</button>
                </Link>
                <button className='g-button' onClick={() => setShowImport(!showImport)}>Import from another game</button>
            </div>
			<div className='competitor-content'>
				<div className='left-col'>
					{
						(showImport) 
						?
						<CompetitorImport competitors={competitors} />
						:
						<NewCompetitor competitors={competitors} />
						}
				</div>
				<div className='right-col'>
					<CompetitorList competitors={competitors} />
				</div>
			</div>
		</div>
	)
}

export default Competitors