import React from "react"
import './sliding-user-info.styles.scss'

import UserInfoCol from '../user-info-col/user-info-col.component'

const SlidingUserInfo = ({ leaders, matchups, userId }) => {

	return (
		<div id="sliding-user-info" data-uk-offcanvas="flip: true; overlay: true">
			<div className="uk-offcanvas-bar menuDiv">
				<button className="uk-offcanvas-close" type="button" data-uk-close></button>
				<UserInfoCol leaders={leaders} matchups={matchups} userId={userId} />
				
			</div>
		</div>
	)
}

export default SlidingUserInfo