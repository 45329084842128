import React, { useEffect, useState } from 'react'
import './view-user.styles.scss';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ViewUser = () => {

    const leaders = useSelector(state => state.gameData.leaders)
    const [ user, setUser ] = useState(null);
    const { appId } = useParams();

    useEffect(() => {
        if (!leaders || !appId) {return;}
        setUser(leaders[appId])
        console.log(leaders[appId]);
    }, [leaders, appId])

    return (
        <div className='view-user-page'>
            <div className='view-user-content'>
                <div>
                    <Link to=".." relative="path">
                        <button className='g-button'>Back</button>
                    </Link>
                </div>
                {
                    (user) &&
                    <div className='user-info'>
                        <div className='user-image'>
                            <img src={user.icon} className='image' />
                        </div>
                        {appId}
                    </div>
                }
            </div>        
        </div>
    )
}

export default ViewUser