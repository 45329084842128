import React from 'react'
import './pick-admin.styles.scss'
import { Link, useNavigate } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarDays, faCoins, faTrophy, faFootball, faArchive, faMessage, faUsers, faGear } from '@fortawesome/free-solid-svg-icons'
import UIkit from 'uikit'
import { UseCloudFunction } from '@/firebase-utils/firebase.utils'
import { useSelector } from 'react-redux'

const PickAdmin = ({ archiveWorld }) => {

    const currentGame = useSelector(state => state.gameData.currentGame)
    const navigate = useNavigate();

	return (
		<div className='pick-admin'>
			<div className='admin-buttons'>
                <Link to='manageusers' className='admin-button'>
					<button className='g-button no-pad' style={{'width':'100%'}}>
						<table>
							<tbody>
								<tr>
									<td><FontAwesomeIcon icon={faUsers} size="lg" /></td>
									<td>Manage Users</td>
								</tr>
							</tbody>
						</table>
					</button>
				</Link>
                <Link to='managematchups' className='admin-button'>
					<button className='g-button no-pad' style={{'width':'100%'}}>
						<table>
							<tbody>
								<tr>
									<td><FontAwesomeIcon icon={faCalendarDays} size="lg" /></td>
									<td>Manage Matchups</td>
								</tr>
							</tbody>
						</table>
					</button>
				</Link>
				<Link to='props' className='admin-button'>
					<button className='g-button no-pad' style={{'width':'100%'}}>
						<table>
							<tbody>
								<tr>
									<td><FontAwesomeIcon icon={faCoins} size="lg" /></td>
									<td>Manage Props</td>
								</tr>
							</tbody>
						</table>
					</button>
				</Link>
				<Link to='results' className='admin-button'>
					<button className='g-button no-pad' style={{'width':'100%'}}>
						<table>
							<tbody>
								<tr>
									<td><FontAwesomeIcon icon={faTrophy} size="lg" /></td>
									<td>Record Results</td>
								</tr>
							</tbody>
						</table>
					</button>
				</Link>
				<Link to='competitors' className='admin-button'>
					<button className='g-button no-pad' style={{'width':'100%'}}>
						<table>
							<tbody>
								<tr>
									<td><FontAwesomeIcon icon={faFootball} size="lg" /></td>
									<td>Add Competitors</td>
								</tr>
							</tbody>
						</table>
					</button>
				</Link>
                <Link to='settings' className='admin-button'>
					<button className='g-button no-pad' style={{'width':'100%'}}>
						<table>
							<tbody>
								<tr>
									<td><FontAwesomeIcon icon={faGear} size="lg" /></td>
									<td>Game Settings</td>
								</tr>
							</tbody>
						</table>
					</button>
				</Link>
                <Link to='message' className='admin-button'>
					<button className='g-button no-pad' style={{'width':'100%'}}>
						<table>
							<tbody>
								<tr>
									<td><FontAwesomeIcon icon={faMessage} size="lg" /></td>
									<td>Send a General Message</td>
								</tr>
							</tbody>
						</table>
					</button>
				</Link>
                <a onClick={() => archiveWorld()} className='admin-button'>
					<button className='g-button no-pad' style={{'width':'100%'}}>
						<table>
							<tbody>
								<tr>
									<td><FontAwesomeIcon icon={faArchive} size="lg" /></td>
									<td>Archive World</td>
								</tr>
							</tbody>
						</table>
					</button>
				</a>
			</div>
			
		</div>
	)
}

export default PickAdmin;