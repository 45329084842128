import React, { useEffect, useRef, useState } from 'react'
import './game-landing.styles.scss'
import { useSelector, useDispatch } from 'react-redux'
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom'

import Leaders from '@/components/leaders/leaders.component'
import ChatContainer from '@/components/chat/chat-container.component'
import GameProfile from '@/components/profile/game-profile.component' 

import 'react-toastify/dist/ReactToastify.css';
import Notifications from '@/components/notifications/notifications.component'
import GameInfo from '@/components/game-info/game-info.component'
import useWindowResize from '@/utils/windowSize'
import MobileHeader from '@/components/mobile-header/mobile-header.component'
import HofPage from '../hof/hof-page.page'
import MatchupListContainer from '../admin/matchup-list/matchup-list-container.component'
import PropsListContainer from '../admin/props-list/props-list-container.component'


const GameLanding2 = ({ matchups, leaders, hof, chats, gameProfile, notifications, unread }) => {

	const currentGame = useSelector(state => state.gameData.currentGame)
	const navigate = useNavigate()
	const [ front, setFront ] = useState(false)
	const [ width ] = useWindowResize();
	
	useEffect( () => {
        setTimeout(() => {
            if (!currentGame) {
                navigate('/home')
            }
        }, 1000);
	}, [])

    useEffect(() => {
        if (width > 768) {
            setFront(true)
        }
    }, [width])

	useEffect(() => {
        if (!currentGame) return;
        const bg = document.getElementsByClassName('background');
        bg[0].style.backgroundImage = `url('${currentGame.bg}')`;
        bg[0].style.backgroundSize = 'cover'
	}, [currentGame])

	return (
        <div>
            {
                (currentGame && width && width < 960) &&
                <MobileHeader leaders={leaders} />
            }

            <div className='game-landing'>
                
                <Routes>
                    <Route path='' element={
                        (currentGame && width && width >= 960) 
                            ?
                            <div className='flex-grid'>
                                <div className='game-image-div g-box'>
                                    <img src={currentGame.banner} className='uk-visible@s game-header-img' />
                                </div>
                                    {
                                        (currentGame && currentGame.type == 'pick')
                                        ?
                                        <>
                                            <div className='matchups-div'>
                                                <MatchupListContainer front={front} matchups={matchups} />
                                            </div>
                                            <div className='props-div'>
                                                <PropsListContainer matchups={matchups} />
                                            </div>
                                            
                                        </>
                                        :
                                        (currentGame && currentGame.type == 'hunt')
                                        ?
                                        <>
                                            <div className='col1'>
                                                
                                                
                                            </div>
                                            <div className='col2'>
                                                
                                            </div>
                                        </>
                                        : ''
                                    }
                                <div className='leaders-div'>
                                    <Leaders leaders={leaders} matchups={matchups} front={front} hof={hof} />
                                </div>
                                <div className='chat-div'>
                                    <ChatContainer chats={chats} leaders={leaders} unread={unread} />
                                </div>
                            </div>
                            : 
                            <div className='wide'>
                                <MatchupListContainer matchups={matchups} />
                            </div>
                    } ></Route>
                    <Route path='profile' element={
                        <div className='wide'>
                            {
                                ( currentGame && width && width >= 960) &&
                                 <img src={currentGame.banner} className='ind-header' />
                            }
                            <GameProfile gameProfile={gameProfile} />
                        </div>
                    }></Route>
                    <Route path='chat' element={
                        <div className='wide'>
                            {
                                 (currentGame && width && width >= 960) &&
                                 <img src={currentGame.banner} className='ind-header' />
                            }
                            <ChatContainer chats={chats} leaders={leaders} unread={unread} />
                        </div>
                    }></Route>
                    <Route path='leaders' element={
                        <div className='wide'>
                            {
                                 (currentGame && width && width >= 960) &&
                                 <img src={currentGame.banner} className='ind-header' />
                            }
                            {
                                (currentGame) &&
                                <Leaders leaders={leaders} matchups={matchups} hof={hof} />
                            }
                        </div>
                    }></Route>
                    <Route path='notifications/*' element={
                        <div className='wide'>
                            {
                                 (currentGame && width && width >= 960) &&
                                 <img src={currentGame.banner} className='ind-header' />
                            }
                            <Notifications notifications={notifications} unread={unread} />
                        </div>
                    }></Route>
                    <Route path='info' element={
                        <div className='wide'>
                            {
                                (currentGame && width && width >= 960) &&
                                 <img src={currentGame.banner} className='ind-header' />
                            }
                            <GameInfo />
                        </div>
                    }></Route>
                    <Route path='props' element={
                        <div className='wide'>
                            {
                                (currentGame && width && width >= 960) &&
                                 <img src={currentGame.banner} className='ind-header' />
                            }
                            <PropsListContainer matchups={matchups} />
                        </div>
                    }></Route>
                    <Route path='leaders/hof' element={<HofPage hof={hof} />}></Route>
                </Routes>
            </div>
        </div>
	)
}

export default GameLanding2;