import React from 'react'
import './modal.styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'

const Modal = ({ cancel, show, children, class2, width, height, scrolling, hideCloseButton }) => {
	const showHideClassName = show ? `modal display-block` : `modal display-none`;

  	return (
    	<div className={showHideClassName}>
      		
			<section className={`modal-main ${class2 ? class2 : ''} ${width ? width : ''} ${height ? height : ''}`}>
                {
                    (!hideCloseButton) &&
                    <div className='close-button-div'>
                        <FontAwesomeIcon className='close-button' icon={faXmark} size='lg' onClick={cancel} />
                    </div>
                }
                <div className={scrolling}>
					<div id='modal-children'>
						{children}
					</div>
					<div className='spinner hide'>
						<div className="uk-overlay-default uk-position-cover"></div>
			            <div className="uk-overlay uk-position-center uk-dark">
			            	<center><div data-uk-spinner="ratio: 3"></div></center>
			            </div>
					</div>
				</div>
      		</section>
    	</div>
  	);
}

export default Modal