import { useEffect, useState } from 'react'

import './App.scss'
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';

import { setCurrentToken, setCurrentUser } from './state/slices/userSlice';
import LandingPage from './pages/landing-page/landing-page.page';
import HomePage from './pages/homepage/homepage.page';
import GamePage2 from './pages/game-page/game-page-2.page';
import UserLoad from './pages/user-load/user-load.page';
import LogoutPage from './pages/logout/logout.page';
import { UseGetMessageToken, auth, onMessageListener } from './firebase-utils/firebase.utils';
import { ToastContainer, toast } from 'react-toastify';
import { getFirestore } from 'firebase/firestore';
import AdminPage from './pages/admin-page/admin-page.page';

function App() {
    const profile = useSelector(state => state.userData.profile)
	const currentUser = useSelector(state => state.userData.currentUser)
	// console.log(currentUser)
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const db = getFirestore()

	const [isTokenFound, setTokenFound] = useState(false);
	
    function saveToken(token) {
        dispatch(setCurrentToken(token))
    }

    onMessageListener().then(payload => {
        console.log('push notification', payload);
    }).catch(err => console.log('failed: ', err));

    useEffect(() => {
        UseGetMessageToken(setTokenFound, saveToken);
    }, [])

	useEffect(() => {

        function requestPermission() {
            // alert('Requesting permission...');
            Notification.requestPermission().then((permission) => {
                if (permission === 'granted') {
                    console.log('Notification permission granted.');
                }
            })
        }
        // requestPermission();
        
		
		console.log('checking user')
		
		auth.onAuthStateChanged(async user => {
            console.log('user changed')
            
			if (user) {
                // console.log(user)
                // console.log(currentUser)
				if (!currentUser) {
                    const newUser = {
                        displayName: user.displayName,
                        email: user.email,
                        metadata: {
                        createdAt: Number(user.metadata.createdAt),
                        lastLogInAt: user.metadata.lastLogInAt
                        },
                        uid: user.uid,
                        photoURL: user.photoURL,
                        provider: user.providerData[0].providerId
                    }
                    dispatch(setCurrentUser({...newUser}));
                    navigate('/loading')
                } else {
                    dispatch(setCurrentUser({...currentUser}));
                    if (window.location.pathname.includes('signin')) {
                        navigate('/loading')
                    }
                }
				
			} else {
				console.log('no user')
               
                setTimeout(() => navigate('/'), 3000)
                
				// location.reload()
				// dispatch(setCurrentUser(null))
				// dispatch(setProfile(null))
				// dispatch(setCurrentGame(null))
				// dispatch(setMyGames(null))
				// dispatch(setCompetitors(null))
				// dispatch(setCommishGames(null))
				// dispatch(setMatchups(null))
				// dispatch(setMyPicks(null))
				// dispatch(setLeaders(null))
				// dispatch(setChat(null))
                // dispatch(setMyTokens(null))
				return;
			}
		})
	}, [])

	onMessageListener().then(payload => {
		toast(`${payload.data.title}: ${payload.data.body}`)
        // alert(`${payload.data.title}: ${payload.data.body}`)
	    console.log(payload);
	}).catch(err => console.log('failed: ', err));


  return (
    <div className='main-body'>
			{
				// isTokenFound && 'Notification permission enabled 👍🏻'
			}
			{
				// !isTokenFound && 'Need notification permission ❗️'
			}
			<Routes>
				<Route path='/*' element={<LandingPage />}></Route>
				<Route path='/home/*' element={<HomePage />}></Route>
				<Route path='/gameadmin/*' element={<AdminPage />}></Route>
				<Route path='/game/*' element={<GamePage2 />}></Route>
                <Route path='/logout' element={<LogoutPage />}></Route>
                <Route path='/loading' element={<UserLoad />}></Route>
			</Routes>
            <ToastContainer
				position="bottom-right"
				autoClose={3000}
				limit={1}
				hideProgressBar
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="light"
			/>
		</div>
    );
}

export default App
