import React, { useEffect, useRef, useState } from 'react'
import './chat-item.styles.scss'

import { format } from 'date-fns'
import { useDispatch, useSelector } from 'react-redux'
import { setUnread } from '@/state/slices/gameSlice'
import { UseCloudFunction } from '@/firebase-utils/firebase.utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamation, faExclamationCircle, faThumbsUp } from '@fortawesome/free-solid-svg-icons'
import pro from '@/assets/pro.png';
import Linkify from 'linkify-react'

const ChatItem = ({ mess, icon, setChatToView, setReplyId, replyButton, isReply, unread }) => {

    const currentGame = useSelector(state => state.gameData.currentGame)
    const userProfile = useSelector(state => state.userData.profile)
    const [ linkifyOptions ] = useState({'target': '_blank'});
    const [ unreadReplies, setUnreadReplies ] = useState(0);
    const unreadRef = useRef();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!unread || !mess) return;
        delete unread.status;
        let replyCount = 0;
        for (let u of Object.values(unread)) {
            if (mess.replies && mess.replies[u.id]) {
                replyCount++;
            }
        }
        setUnreadReplies(replyCount);
    }, [unread, mess])

	function openChat() {
		setChatToView(mess)
		setReplyId(mess.id)
	}

    async function removeUnread(id) {
        const res = await UseCloudFunction('removeUnread', {'noteId': id, 'gameId': currentGame.gameId, 'appId': userProfile.appId})
        console.log(res)
    } 

    async function toggleLike(chatId) {
        console.log(chatId)
        const res = await UseCloudFunction('toggleLike', {
            'appId': userProfile.appId,
            'chatId': chatId,
            'ts': new Date().getTime()
        })
        console.log(res)
    }

    if (!mess.original) {
        return (
            <div className={`chat-item ${unread && unread[mess.id] ? 'jello-horizontal' : ''}`} id={mess.id} onClick={() => (unread && unread[mess.id]) ? removeUnread(mess.id) : null} >
                {
                    <div key={mess.ts} className={`chat-bubble ${unread && unread[mess.id] ? 'unread' : ''}`}>
                        <div className='chat-head'>
                            <img src={icon} className='chat-icon' />
                            <div className='chat-head-text'>
                                <div className='chat-content'>{mess.name}</div>
                                <div className='chat-date'>{format(new Date(mess.ts), 'Pp')}</div>
                            </div>
                        </div>
                        <div className="chat-text">  
                            {/* {mess.ts} {mess.latest} */}
                            <div className='chat-content'>
                                <Linkify options={linkifyOptions}>
                                    {mess.text}
                                </Linkify>
                            </div>
                            {
                                (mess.image) &&
                                <div data-uk-lightbox='animation:fade'>
                                    <a href={mess.image}>
                                        <img src={mess.image} className={`chat-image ${!replyButton && 'reply'}`} alt='chat upload' loading='lazy' />
                                    </a>
                                </div>
                                
                            }
                            
                            {
                                (!isReply) &&
                                <div className='comment-like' >
                                    {/* <div style={{'width': '30px'}}></div> */}
                                    <div className='like-button-div' onClick={() => toggleLike(mess.id)}>
                                        <img src={pro} style={{'height': '1em'}} /> {mess.likes ? Object.keys(mess.likes).length : '0'}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>
        )
    } else {
        return (
            <div className={`chat-item ${unread && unread[mess.id] ? 'jello-horizontal' : ''}`} id={mess.id} onClick={() => (unread && unread[mess.id]) ? removeUnread(mess.id) : null} >
                {
                    <div key={mess.ts} className={`chat-bubble ${unread && unread[mess.id] ? 'unread' : ''}`}>
                        <div className='chat-head'>
                            <img src={icon} className='chat-icon' />
                            <div className='chat-head-text'>
                                <div className='chat-content'>{mess.name}</div>
                                <div className='chat-date'>{format(new Date(mess.ts_posted), 'Pp')}</div>
                            </div>
                        </div>
                        <div className="chat-text">  
                            {/* {mess.ts} {mess.latest} */}
                            <div className='chat-content'>{mess.text}</div>  {/* {mess.text.length > 45 ? `${mess.text.substring(0, 45)}...` : mess.text} {mess.text.length > 25 && <a onClick={() => openChat()}>more</a>}*/}
                            {
                                (mess.image) &&
                                <div data-uk-lightbox='animation:fade'>
                                    <a href={mess.image}>
                                        <img src={mess.image} className={`chat-image ${!replyButton && 'reply'}`} alt='upload' loading='lazy' />
                                    </a>
                                </div>
                            }
                                
                            <div className='comment-like' >
                                {
                                    (!isReply) &&
                                    <>
                                    <div className='like-button-div' onClick={() => toggleLike(mess.id)}>
                                        <img src={pro} /> {mess.likes ? Object.keys(mess.likes).length : '0'}
                                    </div>
                                    <div className='reply-button' onClick={() => openChat()}>
                                        {
                                            (mess.replies) 
                                            ?
                                            <div className='reply-div'> 
                                                {
                                                    (unreadReplies > 0) &&
                                                    <div className='new-reply'>
                                                        {unreadReplies}
                                                    </div>
                                                }
                                                <div>
                                                {
                                                    (Object.keys(mess.replies).length > 1) 
                                                    ?
                                                    `${Object.keys(mess.replies).length} Replies`
                                                    :
                                                    `${Object.keys(mess.replies).length} Reply`
                                                }
                                                </div>
                                            </div>
                                            :
                                            'Reply'
                                        } 
                                    </div>
                                    </>    
                                }
                                
                                
                            </div>
                            
                            {/* {
                                mess.replies &&
                                Object.values(mess.replies).sort((a,b) => a.ts < b.ts ? 1 : -1)
                                .map(reply => (
                                    <div className='chat-bubble reply'>
                                        <img src={reply.icon} className='chat-icon' />
                                    </div>
                                ))
                                
                            } */}
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default ChatItem;