import React, { useEffect, useState } from 'react'
import './create-pickem-settings.styles.scss'

const CreatePickEmSettings = ({ settings, setSettings }) => {

	const [streaks, setStreaks] = useState(false)
	
	// useEffect(() => {
	// 	setSettings({...settings, ...{
	// 		'straight': true,
	// 		'userVsUser': false,
	// 		'weekly': false
	// 	}})
	// }, [setSettings, settings])

	const saveSettings = (e, cat) => {
		
		if (cat === 'streaks') {
			setStreaks(!streaks)
			if (!e) {
				console.log(e)
				setSettings({...settings, ...{
					'streakCount': 0,
					'bonus': 0,
					'streaks': false
				}})
			} else {
				setSettings({...settings, ...{
					'streaks': true
				}})
			}
		} else if (cat === 'straight') {
			setSettings({...settings, ...{
				'straight': true,
				'userVsUser': false,
				'weekly': false
			}})
		} else if (cat === 'userVsUser') {
			setSettings({...settings, ...{
				'straight': false,
				'userVsUser': true,
				'weekly': false
			}})
		} else if (cat === 'weekly') {
			setSettings({...settings, ...{
				'straight': false,
				'userVsUser': false,
				'weekly': true
			}})
		} else {
			setSettings({...settings, ...{[cat]: e}})
		}
	}
		
	return (
		<div className='create-settings'>
			
			<table className="uk-table uk-table-middle">
				<tbody>
					<tr>
						<td style={{'width': '40px'}}>
							<input className="uk-checkbox" type="checkbox" value={settings.public ? settings.public : ''} onChange={e => saveSettings(e.target.checked, 'public')} />
						</td>
						<td>Allow players to request to join the game. If unchecked, the game will not appear on any lists.
						</td>
					</tr>
				</tbody>
			</table>

			<div className='g-height-30'></div>
			<b>Leaderboard Settings</b><hr />

			<table className="uk-table uk-table-middle">
				<tbody>
					<tr>
						<td style={{'width': '40px'}}>
							<input 
								className="uk-checkbox" 
								type="checkbox"
								checked={settings.straight ? settings.straight : false}
								onChange={e => saveSettings(e.target.checked, 'straight')}
							/>
						</td>
						<td>Straight Point Totals
						</td>
					</tr>
				</tbody>
			</table>
			
			<table className="uk-table uk-table-middle">
				<tbody>
					<tr>
						<td style={{'width': '40px'}}>
							<input 
								className="uk-checkbox" 
								type="checkbox"
								checked={settings.userVsUser ? settings.userVsUser : false}
								onChange={e => saveSettings(e.target.checked, 'userVsUser')}
							/>
						</td>
						<td>User vs User Matchups
						</td>
					</tr>
				</tbody>
			</table>

			<table className="uk-table uk-table-middle">
				<tbody>
					<tr>
						<td style={{'width': '40px'}}>
							<input 
								className="uk-checkbox" 
								type="checkbox"
								checked={settings.weekly ? settings.weekly : false}
								onChange={e => saveSettings(e.target.checked, 'weekly')}
							/>
						</td>
						<td>Weekly Total Winners
						</td>
					</tr>
				</tbody>
			</table>

			<div className='g-height-30'></div>
			<b>Extra Stuff</b><hr />
		
			<table className="uk-table uk-table-middle">
				<tbody>
					<tr>
						<td style={{'width': '40px'}}>
							<input 
								className="uk-checkbox" 
								type="checkbox"
								onChange={e => saveSettings(e.target.checked, 'streaks')}
							/>
						</td>
						<td>Give bonus for streaks<br />(only applies to matches)
						</td>
					</tr>
				</tbody>
			</table>
			
			{	
				(streaks) 
				? <div>
					<div className="uk-margin">
						<label>How long should the streak be?</label><br />
						<input 
							className="uk-input" 
							type="number" 
							onChange={e => saveSettings(Number(e.target.value), 'streakCount')} 
							value={settings.streakCount ? settings.streakCount : ''}
							style={{'width':'200px'}}
						/>
					</div>
					<div className="uk-margin">
						<label>How many points should be awarded? (This can be changed later)</label><br />
						<input 
							className="uk-input" 
							type="number" 
							onChange={e => saveSettings(Number(e.target.value), 'bonus')} 
							value={settings.bonus ? settings.bonus : ''}
							style={{'width':'200px'}}
						/>
					</div>
				</div>
				: ""
			}
		</div>
	)
}

export default CreatePickEmSettings