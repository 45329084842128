import React, { useState } from 'react'
import './create-game.styles.scss'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import "croppie/croppie.css"
import Croppie from "croppie"

import CreatePickEmSettings from './create-pickem-settings/create-pickem-settings.component'
import CreateHuntSettings from './create-hunt-settings/create-hunt-settings.component'

import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { getFirestore, setDoc, doc } from "firebase/firestore";
import { getDatabase, ref as rtDbRef, set } from "firebase/database";

import UIkit from 'uikit'
import placeholder_icon from '@/assets/placeholder-icon.jpg'
import placeholder_banner from '@/assets/placeholder-banner.jpg'
import placeholder_bg from '@/assets/placeholder-bg.jpg'
import { UseCloudFunction } from '@/firebase-utils/firebase.utils'

const CreateGame = () => {

	const userData = useSelector(state => state.userData.currentUser)
	const profile = useSelector(state => state.userData.profile)
	const [title, setTitle] = useState('')
	const [desc, setDesc] = useState('')
	const [type, setType] = useState('')
	const [icon, setIcon] = useState('')
	const [bg, setBg] = useState('');
	const [banner, setBanner] = useState('')
	const [settings, setSettings] = useState({
        'straight': true,
        'userVsUser': false,
        'weekly': false
    })
	let croppieInstance
	const navigate = useNavigate()
	const storage = getStorage()
	const firestore = getFirestore()
	
	const destroyCroppie = () => {
		const el = document.getElementById("uploaded-icon")
		el.innerHTML = ''
		el.classList.remove("croppie-container");
	}
	
	function handleUpload(file, id) {
		console.log(id)
		if (id === 'iconUpload') {
			destroyCroppie()
			if (file.type.match(/image.*/)) {
				
				const fileType = file.type;
				console.log('An image has been loaded');
				console.log(fileType)
				// Load the image
				if (fileType.indexOf('gif') === -1) {
					const newFile = window.URL.createObjectURL(file)
					const el = document.getElementById("uploaded-icon")
					if (el) {
						croppieInstance = new Croppie(el, {
							viewport: { width: 150, height: 150 },
							boundary: { width: 150, height: 150 },
							showZoomer: true,
							enableOrientation: true
						});
						croppieInstance.bind({
							url: newFile,
						});
					}
					setIcon({id:id, url:newFile, croppie:croppieInstance, name: file.name, type: file.type})
					return;
				}
				const newFile = window.URL.createObjectURL(file)
				setIcon({id:id, url:newFile, file: file})
				const img = `<img src="${newFile}" className="create-icon" />`
				const el = document.getElementById("uploaded-icon")
				el.innerHTML = img
				return;
			}
			UIkit.modal.alert('Please upload an image file.')
			return;
		} else if (id === 'bgUpload') {
			const newFile = window.URL.createObjectURL(file)
			setBg({id:id, url:newFile, file: file})
		} else if (id === 'bannerUpload') {
			const newFile = window.URL.createObjectURL(file)
			setBanner({id:id, url:newFile, file: file})
		}
	}

	// const toggleStreaks = (e) => {
	// 	setStreaks(e)
	// 	if (!e) {
	// 		setStreakCount(0)
	// 		setBonus(0)
	// 	}
	// }
	
	const handleCancel = () => {
		navigate('/home')
	}
	
	const handleSubmit = async (e) => {
		e.preventDefault()
		if ((settings.straight && settings.weekly) || (settings.straight && settings.userVsUser) || (settings.userVsUser && settings.weekly)) {
			UIkit.modal.alert('Please only select one type of leaderboard setting.')
			return;
		}
		if (settings.streaks && (!settings.bonus || !settings.streakCount)) {
			UIkit.modal.alert('You must designate a bonus amount and a streak count if you are using streaks.')
			return;
		}
		if (!title || !desc || !type) {
			UIkit.modal.alert('You must include a title, a description, and a game type.')
			return;
		}
		if (!icon || !banner) {
			UIkit.modal.alert('You must include an icon and a banner.')
			return;
		}
		
		const now = new Date().getTime();
		const imgs = [icon,bg,banner]
		const imageUrls = {}
		for(let img of imgs) {
			console.log(img)
			if (img.file) {
				
				const profileRef = ref(storage, `uploaded-game-icons/${now}-${img.file.name}`)
				const metadata = { contentType: img.file.type }
				await uploadBytes(profileRef, img.file, metadata)
				.then(snap => console.log(snap))
				
				const newURL = await getDownloadURL(profileRef)
				console.log(newURL)
						  
				if (img.id === 'iconUpload') {
					imageUrls.icon = newURL
				}
				else if (img.id === 'bgUpload') {
					imageUrls.bg = newURL
				}
				else if (img.id === 'bannerUpload') {
					imageUrls.banner = newURL
				}
			} else if (img.croppie) {
				const blob = await img.croppie.result({
					type:'blob',
					size: 512
				})
				const now = new Date().getTime();
				const profileRef = ref(storage, `uploaded-game-icons/${now}-${img.name}`)
				const metadata = { contentType: img.type }
				await uploadBytes(profileRef, blob, metadata)
				.then(snap => console.log(snap))
				const newURL = await getDownloadURL(profileRef)
				console.log(newURL)
				imageUrls.icon = newURL
			}
		}
		
		console.log(imageUrls)
		const data = {
            archived: false,
			crEmail: userData.email,
			crName: profile.name,
            appId: profile.appId,
			title: title,
			desc: desc,
			type: type,
			icon: imageUrls.icon,
			bg: imageUrls.bg,
			banner: imageUrls.banner,
			settings: settings,
			ts: now,
            ts_created: now,
			gameId: `${profile.appId}-${now}`,
			crAppId: profile.appId,
            status: 'active'
			
		}
		if (data.settings.public) {
			data.public = true
		}
		const res = await UseCloudFunction('saveGame', {'gameData': data})
		navigate('/home')
	}
	
	return (
		<div className="create-game">
			<div className="g-box">
				<h3>Create a New Game</h3>
				<hr />
				<form className="" onSubmit={handleSubmit}>
					<div className="uk-margin">
						<label>Game Title</label>
						<input className="uk-input" type="text" onChange={(e) => setTitle(e.target.value)} />
					</div>
					<div className="uk-margin">
						<label>Game Description</label>
						<textarea className="uk-textarea" rows="3" onChange={e => setDesc(e.target.value)}></textarea>
					</div>
					<div className="uk-margin">
						<label>Game Type</label>
						<select className="uk-select" onChange={e => setType(e.target.value)}>
							<option value="" >Choose a game type</option>
							<option value="pick">Pick 'Em</option>
							<option value="hunt">Scavenger Hunt</option>
						</select>
					</div>
					
					
					<h4>Game Settings</h4><hr />
					{	
						(type === 'pick')
						?
							<CreatePickEmSettings settings={settings} setSettings={setSettings} />
						:
							(type === 'hunt')
							?
								<CreateHuntSettings settings={settings} setSettings={setSettings} />
							:
								''
					}	
					<h4>Game Icon</h4><hr />
					<div className="g-grid">

						<div className='g-half-col'>
							<div className="upload-button">
								<div data-uk-form-custom >
									<input 
										type="file" 
										id="iconUpload" 
										onChange={(e) => handleUpload(e.target.files[0], e.target.id)}  />
									<button 
										className="uk-button" 
										type="button" 
										tabIndex="-1"
									>Upload Icon</button>
								</div>
							</div>
						</div>

						<div className='g-half-col'>
							<div id="uploaded-icon" className="upload-div">
								{
									(icon) ? <img src={icon.url || icon} className="create-icon" alt='uploaded icon' />
									: <img src={placeholder_icon} className="create-icon" alt='icon placeholder' />
								}
							</div>
						</div>
						
					</div>

					<h4>Game Banner</h4><hr />
					<div className="g-grid">

						<div className="g-half-col">
							<div className="upload-button">
								<div data-uk-form-custom >
									<input 
										type="file" 
										id="bannerUpload" 
										onChange={(e) => handleUpload(e.target.files[0], e.target.id)}  />
									<button 
										className="uk-button" 
										type="button" 
										tabIndex="-1"
									>Upload Banner</button>
								</div>
							</div>
						</div>
						<div className="g-half-col">
							<div className="upload-button">
								<div id="uploaded-banner" className="upload-div">
									{
										(banner) ? <img src={banner.url || banner} className="create-banner" alt='uploaded banner' />
										: <img src={placeholder_banner} className="create-banner" alt='banner placeholder' />
									}
								</div>
							</div>
						</div>
						
						
					</div>
					<h4>Game Background (Optional)</h4><hr />
					<div className="g-grid">
						
						<div className='g-half-col'>
						
							<div className="upload-button">
								<div data-uk-form-custom style={{'cursor': 'pointer'}}>
									<input 
										type="file" 
										id="bgUpload" 
										onChange={(e) => handleUpload(e.target.files[0], e.target.id)}  />
									<button 
										className="uk-button" 
										type="button" 
										tabIndex="-1"
									>Upload Background</button>
								</div>
							</div>
						</div>
						<div className='g-half-col'>
							<div id="uploaded-bg" className="upload-div">
								{
									(bg) ? <img src={bg.url || bg} className="create-bg" alt='uploaded background' />
									: <img src={placeholder_bg} className="create-bg" alt='background placeholder' />
								}
							</div>
						</div>
					</div>
					<div className='g-height-30'></div>
					<hr />
					<div className='buttons'>
						
						<button className='uk-button' onClick={() => handleCancel()}>
							Cancel
						</button>
						<button className='uk-button uk-button-primary' type="submit">
							Create Game
						</button>
					</div>
				</form>
			</div>
		</div>
	)
}

export default CreateGame