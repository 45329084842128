import React, { useEffect, useRef, useState } from 'react'

import GameAdmin from '@/components/admin/game-admin/game-admin.component';
import { UseCloudFunction, getCurrentToken } from '@/firebase-utils/firebase.utils';
import { setMyTokens, setSavedToken } from '@/state/slices/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getMatchupsFromDb, getQueryFromDb, getUniqueDocFromDb } from '@/utils/getDataFromDb';
import { setCurrentGame } from '@/state/slices/gameSlice';

const AdminPage = () => {
    
    const currentGame = useSelector(state => state.gameData.currentGame);
	const userProfile = useSelector(state => state.userData.profile);
    const currentToken = useSelector(state => state.userData.currentToken);
    const myTokens = useSelector(state => state.userData.myTokens);
    const [ hof, setHof ] = useState(null);
    const [ matchups, setMatchups ] = useState(null);
    const [ leaders, setLeaders ] = useState(null);
    const [ picks, setPicks ] = useState(null);
    const [ chats, setChats ] = useState(null);
    const [ unread, setUnread ] = useState(null);
    const [ notifications, setNotifications ] = useState(null);
    const [ competitors, setCompetitors ] = useState(null);
    const matchupsRef = useRef();
    const chatsRef = useRef();
    const notificationsRef = useRef();
    let matchupsListener;
    let leadersListener;
    let picksListener;
    let chatsListener;
    let notificationsListener;
    let competitorsListener;
	const dispatch = useDispatch();
	const navigate = useNavigate();

    useEffect(() => {

        
        setTimeout(() => {
            if (!matchupsRef.current) {
                setMatchups('none');
            }
        }, 4000)

        return () => {
            if (matchupsListener) matchupsListener();
            if (leadersListener) leadersListener();
            if (picksListener) picksListener();
            if (chatsListener) chatsListener();
            if (notificationsListener) notificationsListener();
            if (competitorsListener) competitorsListener();
            dispatch(setCurrentGame(null));
        }
    }, [])

    useEffect(() => {
        if (!currentGame || !userProfile) return;
        getGameData();
    }, [currentGame, userProfile])

    useEffect(() => {
        if (userProfile && currentGame && currentToken) {
            // saveNewToken(currentToken)
        }
        async function saveNewToken(token) {
            if (!token) {
                token = await getCurrentToken();
            }
            console.log('saveNewToken')
            if (!myTokens || myTokens[currentGame.gameId] !== token) {
                console.log('saving New Token')
                const res = await UseCloudFunction('saveNewToken', {
                    'email': userProfile.email,
                    'appId': userProfile.appID,
                    'newToken': currentToken,
                    'device': navigator.userAgent
                })
                console.log(res)
                dispatch(setSavedToken(currentToken))
                dispatch(setMyTokens({...myTokens, ...{[currentGame.gameId]: currentToken}}))
            }
        }

    }, [userProfile, currentGame, currentToken])

    function getGameData() {
        getHofData();
        getMatchups();
        getLeaders();
        getPicks();
        // getChats();
        getNotifications();
        getCompetitors();
    }

    async function getHofData() {
        if (currentGame.hof) {
            getUniqueDocFromDb({'collection': 'hof', 'docId': currentGame.hof, 'once': true, 'callback': callback})
            function callback(data) {
                setHof(data);
            }
        }
    }

    function getMatchups() {
        getMatchupsFromDb({'gameId': currentGame.gameId, 'status': 'active', 'callback': callback, 'handleListener': handleListener})
        function callback(data) {
            const tempList = {...matchupsRef.current};
            if (data.status === 'active') {
                tempList[data.id] = data
            } else {
                delete tempList[data.id];
            }
            matchupsRef.current = {...tempList}
            setMatchups(matchupsRef.current);
        }
        function handleListener(unsub) {
            matchupsListener = unsub;
        }
    }

    function getLeaders() {
        getUniqueDocFromDb({'collection': 'leaders', 'docId': currentGame.gameId, 'callback': callback, 'handleListener': handleListener})
        function callback(data) {
            setLeaders(data);
        }
        function handleListener(unsub) {
            leadersListener = unsub;
        }
    }

    function getPicks() {
        getUniqueDocFromDb({'collection': 'picks', 'docId': `${currentGame.gameId}_${userProfile.appId}`, 'callback': callback, 'handleListener': handleListener})
        function callback(data) {
            setPicks(data);
        }
        function handleListener(unsub) {
            picksListener = unsub;
        }
    }

    function getChats() {
        getQueryFromDb({'collection': 'chats', 'gameId': currentGame.gameId, 'status': 'active', 'callback': callback, 'handleListener': handleListener})
        function callback(data) {
            chatsRef.current = {...chatsRef.current, ...{[data.id]: data}}
            setChats(chatsRef.current);
        }
        function handleListener(unsub) {
            chatsListener = unsub;
        }
    }

    function getNotifications() {
        getQueryFromDb({'collection': 'notifications', 'gameId': currentGame.gameId, 'status': 'active', 'callback': callback, 'handleListener': handleListener})
        function callback(data) {
            notificationsRef.current = {...notificationsRef.current, ...{[data.id]: data}}
            setNotifications(notificationsRef.current);
        }
        function handleListener(unsub) {
            notificationsListener = unsub;
        }
    }

    function getCompetitors() {
        getUniqueDocFromDb({'collection': 'competitorList', 'docId': currentGame.gameId, 'callback': callback, 'handleListener': handleListener})
        function callback(data) {
            setCompetitors(data);
        }
        function handleListener(unsub) {
            competitorsListener = unsub;
        }
    }

    function getUnread() {
        getDataFromRtDb({'path': `unread/${currentGame.gameId}/${userProfile.appId}`, 'callback': callback, 'handleListener': handleListener})
        function callback(data) {
            setUnread(data);
        }
        function handleListener(unsub) {
            unreadListener = unsub;
        }
    }


    return (
        <div className='admin-page'>
            <GameAdmin 
                picks={picks} 
                matchups={matchups} 
                leaders={leaders} 
                hof={hof} 
                // chats={chats}
                notifications={notifications}
                competitors={competitors}
            />
        </div>
    )
}

export default AdminPage