import React, { useState, useEffect } from 'react'
import './chat-view.styles.scss'

import { useSelector } from 'react-redux'
import ChatItem from '@/components/chat/chat-item.component'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faArrowLeft, faTurnDown } from '@fortawesome/free-solid-svg-icons'

import { getDatabase, ref as dbRef, onValue, off } from "firebase/database";

const ChatView = ({ mess, setChatToView, setReplyId, leaders, unread }) => {

	const [ icon, setIcon ] = useState(null)
	const [ replies, setReplies ] = useState(null) 
	const rtDb = getDatabase();
	const gameData = useSelector(state => state.gameData.currentGame)

	useEffect(() => {
        if (!leaders || !mess || !gameData) return;
		setIcon(leaders[mess.appId].icon)
		setReplies(mess.replies);
        console.log(mess.replies)

	}, [mess, leaders, gameData.gameId, rtDb])
	
	return (

		<div className='chat-view'>
			
			{
				(mess && icon)
				?
				// <div className='g-box'>
					// <ChatItem mess={mess} icon={icon} setChatToView={setChatToView} />
                ''
				// </div>
				: ''
			}
			<div className='replies'>
				{
					(replies)
					?
					Object.values(replies).sort((a,b) => (a.ts < b.ts) ? 1 : -1).map(reply => (
						<div key={reply.id}>
							<ChatItem mess={reply} icon={leaders[reply.appId].icon} replyButton={false} isReply={true} unread={unread} />
							{/* <hr className='mid-item' /> */}
						</div>
					))
					: ''
				}
                <p>Reply below <FontAwesomeIcon icon={faTurnDown} style={{'transform': 'translate(10px, 8px)'}} /></p>
                <div className="g-height-30"></div>
			</div>
		</div>
	)
}

export default ChatView;