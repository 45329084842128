import React, { useEffect } from "react"
import './game-admin.styles.scss'
import { setNextMatchup } from "@/state/slices/gameSlice"
import { useSelector, useDispatch } from 'react-redux'
import { Routes, Route } from 'react-router-dom'

import Header from '../../header/header.component'
import SideNav from '../../side-nav/side-nav.component'
import MobileNav from '../../mobile-nav/mobile-nav.component'
import SlidingUserInfo from '../../sliding-user-info/sliding-user-info.component'
import AdminLanding from '../admin-landing/admin-landing.component'
import Competitors from '../competitors/competitors.component'
import ManageMatchups from '../manage-matchups/manage-matchups.component'
import Results from '../results/results.component'
import ManageProps from '../manage-props/manage-props.component'
import ManageChallenges from '../manage-challenges/manage-challenges.component'
import SendMessage from "../sendMessage/send-message.component"
import ManageUsers from "../manage-users/manage-users.component"
import Settings from "../settings/settings.component"
import ViewUser from "../manage-users/view-user.component"

const GameAdmin = ({ picks, matchups, leaders, hof, notifications, competitors }) => {

	const currentGame = useSelector(state => state.gameData.currentGame)
	const dispatch = useDispatch()
	
	useEffect(() => {
        if (!currentGame) return;
		console.log('mounting')
		const bg = document.getElementsByClassName('game-admin');
		bg[0].style.backgroundImage = `url('${currentGame.bg}')`;
		bg[0].style.backgroundSize = 'cover'	
		
	}, [currentGame])
	
	return (
		<div className='game-admin'>
			<Header />
			<SideNav />
			<MobileNav />
			<SlidingUserInfo />
			{
				(currentGame)
				? <img src={currentGame.banner} className='admin-banner' />
				: ''
			}
			<div className='admin-content-container'>
				<Routes>
					<Route path='' element={<AdminLanding />}></Route>
					<Route path='/competitors' element={<Competitors competitors={competitors}  />}></Route>
					<Route path='/managematchups' element={<ManageMatchups matchups={matchups} competitors={competitors} />}></Route>
					<Route path='/props' element={<ManageProps matchups={matchups} />}></Route>
					<Route path='/results' element={<Results matchups={matchups} />}></Route>
					<Route path='/managechallenges' element={<ManageChallenges />}></Route>
                    <Route path='/message' element={<SendMessage notifications={notifications} />}></Route>
                    <Route path='/manageusers' element={<ManageUsers leaders={leaders} />}></Route>
                    <Route path='/manageusers/:appId' element={<ViewUser leaders={leaders} />}></Route>
                    <Route path='/settings' element={<Settings />}></Route>
				</Routes>
			</div>
		</div>
	)		
}

export default GameAdmin;