import React, { useEffect, useState } from 'react'
import './hof-page.styles.scss';
import hof_head from '@/assets/hof_head.png'
import { useSelector } from 'react-redux';

const HofPage = ({ hof }) => {

    const [ hofDisplay, setHofDisplay ] = useState(null);

    useEffect(() => {
        if (!hof) {return;}
        setHofDisplay([...hof.hof].reverse());
    }, [hof])

    return (
        <div className='hof-page'>
            <div className='hof-content'>
                <div className='head-div'>
                    <img src={hof_head} className='hof-head' />
                    <div className='g-box head-box'>
                        {hof ? hof.name : ''}
                    </div>
                </div>
                <div className="g-space-1"></div>
                <div className='hof-items'>
                {
                    (hofDisplay) &&
                    hofDisplay.map(h => (
                        <div key={h.id} className='hof-item'>
                            <img src={h.image} className='user-image' />
                            <div className='info'>
                                <div className='name'>{h.name}</div>
                                <div className='data1'>{h.data1}</div>
                                <div className='data2'>{h.data2}</div>
                                <div className='data3'>{h.data3}</div>
                            </div>
                        </div>
                    ))
                }
                </div>
            </div>
        </div>
    )
}

export default HofPage