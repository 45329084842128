import React, { useEffect, useRef } from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import './landing-page.styles.scss'

import LandingContent from '../../components/landing-content/landing-content.component'
import SignIn from '../../components/sign-in/sign-in.component'
import Registration from '../../components/registration/registration.component'
import Profile from '../../components/profile/profile-container.component'
import { getAuth } from 'firebase/auth'
import { setProfile } from '@/state/slices/userSlice'
import { getUniqueDocFromDb } from '@/utils/getDataFromDb'

const LandingPage = () => {

	const userProfile = useSelector(state => state.userData.profile)
    const currentUser = useSelector(state => state.userData.currentUser)
	const navigate = useNavigate();
    const dispatch = useDispatch();
    let profileListener;
	
	useEffect(() => {

		const interval = setInterval(() => {
            const auth = getAuth();
            const user = auth.currentUser;
			const currentURL = window.location
			// console.log(currentURL)
			if ((user && currentURL.pathname !== '/profile') || (userProfile && currentUser && currentURL.pathname !== '/profile')) {
				clearInterval(interval);
                navigate('/loading') 
			}
			// console.log(profile)
		}, 2000)

		return () => {
			clearInterval(interval)
            if (profileListener) profileListener();
		}
		
	}, [])

    useEffect(() => {
        if (!currentUser) return;
        getProfile();

    }, [currentUser])


    function getProfile() {
        getUniqueDocFromDb({'collection': 'users', 'docId': currentUser.email, 'callback': callback, 'handleListener': handleListener})
        function callback(data) {
            dispatch(setProfile(data));
        } 
        function handleListener(unsub) {
            profileListener = unsub;
        }
    }
	
	return (
		<div className='landing-page'>
			<div className='content'>
				<Routes>
					<Route path='' element={<LandingContent />}></Route>
					<Route path='/signin' element={<SignIn />}></Route>
					<Route path='/register' element={<Registration />}></Route>
					<Route path='/profile' element={<Profile profile={userProfile} />}></Route>
				</Routes>
			</div>
		</div>
	)
}

export default LandingPage