import React from 'react';
import './admin-prop-item.styles.scss';

import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';

const AdminPropItem = ({ matchup, editProp, deleteProp }) => {
    return (
        <div className='g-box admin-prop'>
            <div className='desc-div'>
                <div className="desc-title">
                    {matchup.name}
                </div>
                <div className="desc-desc">
                    {matchup.desc}
                </div>
                <div className='details'>
                    <div>
                        {matchup.comp1}
                    </div>
                    <div>vs</div>
                    <div>
                        {matchup.comp2}
                    </div>
                </div>
                <div className='date'>{format(new Date(matchup.date), "E, Pp")}</div>
                <div className='buttons'>
                    <button className='g-button small-btn' onClick={() => editProp(matchup)}><FontAwesomeIcon icon={faPen} /></button>
                    <button className='g-button small-btn' onClick={() => deleteProp(matchup)}><FontAwesomeIcon icon={faTrash} /></button>
                </div>
            </div>
        </div>
    )
}

export default AdminPropItem