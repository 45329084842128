import React from 'react'
import './next-matchup.styles.scss'
import { useSelector } from 'react-redux'

const NextMatchup = () => {

	const nextMatchup = useSelector(state => state.gameData.nextMatchup)
	
	return (
		<div className='next-game'>
			<h4>Next Matchup</h4>
			<hr />
			{
				(nextMatchup) 
				?
					<h3>{nextMatchup.name}</h3>
				: ''
			}
		</div>
	)
}

export default NextMatchup;

	// {
			// 	(matchups) 
			// 	? ((Object.values(matchups).filter(match => match.date > new Date().getTime())).length > 0)
			// 		?				
			// 		<h3>
			// 			{
							
			// 				Object.values(matchups).sort((a,b) => (a.date > b.date) ? 1 : -1)
			// 				.filter(match => match.date > new Date().getTime())[0].name}<br />
			// 				{
			// 					format((Object.values(matchups).sort((a,b) => (a.date > b.date) ? 1 : -1)
			// 					.filter(match => match.date > new Date().getTime())[0].date), "h:mm bbb EEE, L/d/y")
			// 				}
			// 			}
			// 		</h3>
			// 		: <h3>No matchups upcoming </h3>
			// 	: ''
			// }