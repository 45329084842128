import React, { useEffect, useRef, useState } from 'react'
import './home-landing.styles.scss'
import { useSelector, useDispatch } from 'react-redux'
import { setArchivedCommishGames, setCommishGames, setCompetitors } from '@/state/slices/gameSlice'

import CommishList from './commish-list/commish-list.component'
import MyGames from './my-games/my-games.component'
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from '../../firebase-utils/firebase.utils'
import { version } from '@/changelog-version'

const HomeLanding = ({ gamesList }) => {

    const userProfile = useSelector(state => state.userData.profile);
    const [ commishList, setCommishList ] = useState([])
    const [ playerList, setPlayerList ] = useState([])
    const [ openList, setOpenList ] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!gamesList || !userProfile) return;
        // console.log(gamesList)
        setCommishList(Object.values(gamesList).filter(g => g.list.includes(userProfile.appId) && (g.roles[userProfile.appId].includes('owner') || g.roles[userProfile.appId].includes('manager')) && g.status === 'active'));
        setPlayerList(Object.values(gamesList).filter(g => g.list.includes(userProfile.appId) && g.roles[userProfile.appId].includes('player') && g.status === 'active'));
        setOpenList(Object.values(gamesList).filter(g => (!g.list.includes(userProfile.appId) || !g.roles[userProfile.appId].includes('player')) && g.status === 'active'));
    } ,[gamesList, userProfile])

    useEffect(() => {
        if (!commishList) return;
        dispatch(setCommishGames(commishList));
    }, [commishList])

	return (
		<div className='home-landing'>
			<div className="home-grid">
                <div className="col col1">
                    <MyGames playerList={playerList} openList={openList} />
                </div>
                {
                    (commishList.length > 0) &&
                    <div className="col col2">
                        <CommishList commishList={commishList} />
                        
                        <div className="g-height-30"></div>
                    </div>
                }
                
            </div>
        </div>
	)
}

export default HomeLanding