import React from 'react'
import './notification-settings.styles.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faBell, faGear } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'

const NotificationSettings = () => {

    const navigate = useNavigate();

    function toggleSwitch() {
        const el = document.getElementById('checkbox')
        el.checked = !el.checked;
        // saveData('visible', el.checked)
    }

    return (
        <div>
            
            <span className='main'>
                <FontAwesomeIcon icon={faArrowLeft} className='clickable head-icon' onClick={() => navigate('/game/notifications')} />
                Push Notification Settings
            </span>
            <hr />
            <div className='g-height-20'></div>
            <div className='toggle-with-text'>
                
                <div className='toggle-text'>
                    Messages from the game leader
                </div>
                <div className='toggle-button'>
                    <label className="switch" onClick={() => toggleSwitch()}>
                        <input type="checkbox" id="checkbox" defaultChecked  />
                        <span className="slider round"></span>
                        
                    </label>
                </div>
            </div>
            {/* <hr className='separator' /> */}
            {/* <div className='g-height-20'></div> */}
            <div className="g-height-30"></div>
            <div className='toggle-with-text'>
                
                <div className='toggle-text'>
                    New chat messages
                </div>
                <div className='toggle-button'>
                    <label className="switch" onClick={() => toggleSwitch()}>
                        <input type="checkbox" id="checkbox" defaultChecked  />
                        <span className="slider round"></span>
                        
                    </label>
                </div>
            </div>
        </div>
    )
}

export default NotificationSettings