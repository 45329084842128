import React, { useEffect, useState } from 'react'
import './manage-users.styles.scss'

import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { rtDb } from '@/firebase-utils/firebase.utils'
import { child, get, ref, set } from 'firebase/database'
import { setLeaders } from '@/state/slices/gameSlice'
import Leaders from '@/components/leaders/leaders.component'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleRight, faSave, faTrash } from '@fortawesome/free-solid-svg-icons'
import UIkit from 'uikit'

const ManageUsers = () => {

    const leaders = useSelector(state => state.gameData.leaders)
    const gameId = useSelector(state => state.gameData.currentGame.gameId)
    const [ notes, setNotes ] = useState({})
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const dbRef = ref(rtDb);
        if (!leaders) {
            get(child(dbRef, `leaders/${gameId}`)).then((snapshot) => {
            if (snapshot.exists()) {
                dispatch(setLeaders(snapshot.val()))
            } else {
                console.log("No data available");
            }
            }).catch((error) => {
                console.error(error);
            });
        }
        if (!Object.keys(notes).length) {
            get(child(dbRef, `notes/${gameId}`)).then((snapshot) => {
                if (snapshot.exists()) {
                    setNotes(snapshot.val())
                } else {
                    console.log("No data available");
                }
            }).catch((error) => {
                console.error(error);
            });
        }
    }, [])

    async function saveNotes(appId) {
        console.log(appId);
        set(ref(rtDb, `notes/${gameId}/${appId}`), {
            'notes': notes[appId],
            'ts': new Date().getTime()
        });
        UIkit.modal.alert('Note saved successfully.');
    }

    return (
        <div className='manage-users'>
            <div>
                <Link to='/gameadmin'>
                    <button className='g-button'>Back</button>
                </Link>
            </div>
            <div className="g-grid">
                <div className="g-col user-list">
                    <table className='g-table'>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Notes</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            leaders && notes &&
                            Object.values(leaders).sort((a, b) => (a.lName > b.lName) ? 1 : (a.lName === b.lName) ? ((a.fName > b.fName) ? 1 : -1) : -1 )
                            .map(user => (
                                <tr key={user.appID} className='user-item'>
                                    <td>{user.fName} {user.lName}</td>
                                    <td>
                                        <input className='uk-input' value={notes[user.appID] ? notes[user.appID].notes : ''} type='text' id={`notes-${user.appID}`} onChange={(e) => setNotes({...notes, ...{[user.appID]: e.target.value}})} />
                                        <FontAwesomeIcon icon={faSave} className='clickable' onClick={() => saveNotes(user.appID)}/>
                                    </td>
                                    <td className='icon-td'>
                                        <div className='icons'>
                                            <FontAwesomeIcon icon={faTrash} className='clickable' />
                                            <FontAwesomeIcon icon={faArrowAltCircleRight} className='clickable' onClick={() => navigate(user.appID)} />
                                        </div>
                                    </td>
                                </tr>
                            ))
                            
                        }
                        </tbody>
                    </table>
                    
                </div>
                {/* <div className="g-half-col leaders-list">
                    <Leaders />
                </div> */}
            </div>
                
        </div>
    )
}

export default ManageUsers