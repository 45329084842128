import React, { useState } from 'react'
import './send-message.styles.scss'

import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import UIkit from 'uikit'
import { UseCloudFunction } from '@/firebase-utils/firebase.utils'

const SendMessage = () => {

    const currentGame = useSelector(state => state.gameData.currentGame);
    const userProfile = useSelector(state => state.userData.profile)
    const [ sending, setSending ] = useState(false)

    async function sendMessage() {
        setSending(true);
        const now = new Date().getTime();
        const data = {
            'title': document.getElementById('title').value,
            'text': document.getElementById('body').value,
            'category': 'general',
            'gameId': currentGame.gameId,
            'ts': now,
            'email': userProfile.email,
            'icon': currentGame.icon,
            'id': now + '_message',
        }
        if (!data.title || !data.text) {
            UIkit.modal.alert('Please complete the form before continuing.')
            return;
        }
        const res = await UseCloudFunction('sendMessage', {'content': data})
        console.log(res);
        if (res.success) {
            UIkit.modal.alert('Message sent successfully.')
            document.getElementById('title').value = ''
            document.getElementById('body').value = ''
            setSending(false);
        } else {
            UIkit.modal.alert('Something went wrong. Try again later.')
            setSending(false);
        }
    }

    return (
        <div className='send-message'>
            <div className="content-box">
                <Link to='/gameadmin'>
                    <button className='g-button'>Back</button>
                </Link>

                <div className="uk-margin">
                    <input className='uk-input' id='title' placeholder='Title...' />
                </div>
                <div className="uk-margin">
                    <textarea className='uk-textarea' id='body' placeholder='Body...' rows='3'></textarea>
                </div>
                <div className="uk-margin">
                    {
                        sending 
                        ?
                        <button className='g-button'><div data-uk-spinner='ratio: 0.5'></div></button>
                        :
                        <button className='g-button' onClick={() => sendMessage()}>Send Message</button>
                    }
                </div>
            </div>
        </div>
    )
}

export default SendMessage