import React, { useEffect, useRef, useState } from 'react'
import './chat-input.styles.scss'

import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImage, faShare, faCircleXmark, faRotateRight } from '@fortawesome/free-solid-svg-icons'

import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'

import { UseCloudFunction } from '@/firebase-utils/firebase.utils'
import { resizeImage } from '@/utils/image-resize'

const ChatInput = ({ replyId }) => {

	const [ image, setImage ] = useState(null)
	const [ text, setText ] = useState('')
	const [ sending, setSending ] = useState(false)
	const gameData = useSelector(state => state.gameData.currentGame)
	const profile = useSelector(state => state.userData.profile)
	const storage = getStorage();
    const imageRef = useRef();
	
	useEffect(() => {
		const input = document.getElementById('chat-text-input')
		input.addEventListener("keydown", (event) => {
 		 	input.style.removeProperty('height');
			input.style.height = (input.scrollHeight+2) + 'px';
		});
		input.addEventListener('mousedown', (event) => {
			input.style.removeProperty('height');
			input.style.height = (input.scrollHeight+2) + 'px';
		});
	}, [])

	async function handleUpload(file) {
		const newFile = window.URL.createObjectURL(file)
        if (!file.type.includes('gif')) { 
            const resizedImage = await resizeImage(newFile)
            console.log(resizedImage)
            imageRef.current = {url:resizedImage.url, file: resizedImage.blob, name: file.name, type: file.type}
            setImage(imageRef.current);
        } else {
            imageRef.current = {url:newFile, file: file, name: file.name, type: file.type}
            setImage(imageRef.current);
        }
        console.log(imageRef.current)
		const input = document.getElementById('chat-text-input')
		input.classList.remove('no-image')
		input.classList.add('image-uploaded')
	}

	async function handleSend(e) {
		setSending(true);
		console.log('sending chat')
		console.log(text)
        console.log(imageRef.current)
		if (!text && !(imageRef.current && imageRef.current.file)) {
			console.log('no text or image')
			setSending(false);
			return;
		}
		const now = new Date().getTime();
		let newURL = '';
		if (imageRef.current && imageRef.current.file) {
            console.log(imageRef.current);
			const chatRef = ref(storage, `${gameData.gameId}-chat/${now}-${imageRef.current.name}`)
			const metadata = { contentType: imageRef.current.type }
			await uploadBytes(chatRef, imageRef.current.file, metadata)
			.then(snap => console.log(snap))
				
			newURL = await getDownloadURL(chatRef)
			console.log(newURL)
		}
		const data = {
			'gameId': gameData.gameId,
			'user': profile,
			'chat': {
				'text': text,
				'image': newURL,
				
			},
			'ts': now
		}
		if (replyId) {
			data.chat.replyId = replyId
		}
		console.log(data)
		await UseCloudFunction('submitChat', data)
		.then(res => {
			console.log(res)
			setText('')
			setImage(null)
			setSending(false);
		}).catch(err => console.log(err))
        const newChat = document.getElementById(`${now}_chat`)
        // newChat.scrollIntoView({'behavior': 'smooth'});
        document.getElementById("fileUpload").value=null;
	}
	
	return (
		<div className='chat-input'>
			<div className='chat-input-content'>
				<div style={{cursor: 'pointer'}}>
					<input type="file" accept="image/*" id="fileUpload" onChange={(e) => handleUpload(e.target.files[0])}  />
					<FontAwesomeIcon className='icon-link image-icon' icon={faImage} />
				</div>
				<textarea type='text' placeholder={(replyId) ? 'Reply...' : ''} id='chat-text-input' rows='1' className='uk-textarea chat-input-box no-image' value={text} onChange={e => setText(e.target.value)}></textarea>
				{
					(sending)
					? 
					<FontAwesomeIcon className='icon-link icn-spinner' icon={faRotateRight} />
					: 
					<FontAwesomeIcon className='icon-link' icon={faShare} onClick={(e) => handleSend()} />
				}
				
			</div>
			{
				(image) 
				? 
				<div className='image-thumb-container'>
					<img src={image.url} className='image-thumb' alt='upload thumbnail' />
					<FontAwesomeIcon className='icon-link' icon={faCircleXmark} onClick={e => setImage(null)}  />
				</div>
				: ''
			}
		</div>
	)
}

export default ChatInput;