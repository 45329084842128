import React, { useRef, useEffect } from 'react'
import './notifications.styles.scss'

import { faBell, faGear } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Route, Routes, useNavigate } from 'react-router-dom'
import NotificationSettings from './notification-settings.component'
import { useDispatch, useSelector } from 'react-redux'
import { UseCloudFunction } from '@/firebase-utils/firebase.utils'
import { setUnread } from '@/state/slices/gameSlice'
import { format } from 'date-fns'
import useWindowResize from '@/utils/windowSize'
import { makeLinkActive } from '@/utils/active-links'

const Notifications = ({ notifications, unread }) => {

    const currentGame = useSelector(state => state.gameData.currentGame);
    const userProfile = useSelector(state => state.userData.profile);
    const [ width, height ] = useWindowResize();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const unreadRef = useRef()
    unreadRef.current = {...unread}

    useEffect(() => {
        makeLinkActive('notifications-link')
    }, [])

    async function removeUnread(id) {
        delete unreadRef.current[id]
        dispatch(setUnread({...unreadRef.current}))
        const res = await UseCloudFunction('removeUnread', {'noteId': id, 'gameId': currentGame.gameId, 'appId': userProfile.appId})
        console.log(res)
    } 

    return (
        <div className='notifications'>
            <div className='g-box head-box ' style={{height: height-235}}>
                <Routes>
                    <Route path='' element={
                        <>
                        <span className='main'>
                            <div className='head-with-settings'>
                                <div><FontAwesomeIcon icon={faBell} className='head-icon' />
                                Notifications
                                </div>
                                {/* <FontAwesomeIcon icon={faGear} className='clickable' onClick={() => navigate('settings')} /> */}
                            </div>
                            <hr />
                        </span>
                        <div className='messages'  style={{height: height-290}}>
                       
                        {
                            (notifications && (!unread || unread)) 
                            ?
                            Object.values(notifications)
                            .sort((a,b) => a.ts < b.ts ? 1 : -1)
                            .map(note => (
                                (unread && Object.keys(unread).includes(note.id))
                                ?
                                <div key={note.id} className={`message-bubble clickable new`} onClick={() => removeUnread(note.id)}>
                                    <div className="message-head">
                                        <img src={note.icon} className='message-icon' />
                                        <div className='message-title'>
                                            <b>{note.title}</b>
                                            <div className='message-date'>{format(new Date(note.ts), 'Pp')}</div>
                                        </div>
                                    </div>
                                    <div className="message-text">
                                        <div className='message-content'>{note.text}</div>
                                    </div>
                                </div>
                                :
                                <div key={note.id} className={`message-bubble`}>
                                    <div className="message-head">
                                        <img src={note.icon} className='message-icon' />
                                        <div className='message-title'>
                                            <b>{note.title}</b>
                                            <div className='message-date'>{format(new Date(note.ts), 'Pp')}</div>
                                        </div>
                                    </div>
                                    <div className="message-text">
                                        <div className='message-content'>{note.text}</div>
                                    </div>
                                </div>
                            ))
                            : 
                            'No notifications yet...'
                        }
                        </div>
                        </>
                    
                    }></Route>
                    <Route path='settings' element={<NotificationSettings />}></Route>   
                </Routes>
            </div>
        </div>
    )
}

export default Notifications