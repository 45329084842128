import React, { useEffect, useRef, useState } from 'react';
import './pick-modal.styles.scss';
import Modal from '@/components/modal/modal.component';
import { format } from 'date-fns';
import getWagers from '@/utils/wager.utils';
import question_mark from '@/assets/questionmark.png';
import streak_bonus from '@/assets/streak-bonus.png';
import { useSelector } from 'react-redux';
import { UseCloudFunction } from '@/firebase-utils/firebase.utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import UIkit from 'uikit';
import { getPercentages } from './matchup-item.component';
import ThreeDotsLoader from '@/components/loader/three-dots-loader.component';
import { toast } from 'react-toastify';
import { properCase } from '@/utils/properCase';

const PickModal = ({ closeModal, matchup }) => {

    const userProfile = useSelector(state => state.userData.profile);
    const currentGame = useSelector(state => state.gameData.currentGame);
    const [ pick, setPick ] = useState({});
    const [ minWager, setMinWager ] = useState(null);
    const [ pickedWager, setPickedWager ] = useState('');
    const [ loading, setLoading ] = useState(false);
    const [ formData, setFormData ] = useState({});
    const [ pick1Bg, setPick1Bg ] = useState('');
    const [ pick2Bg, setPick2Bg ] = useState('');
    const [ comp1Percent, setComp1Percent ] = useState(0);
    const [ comp2Percent, setComp2Percent ] = useState(0);
    const [ comp1Wagers, setComp1Wagers ] = useState(0);
    const [ comp2Wagers, setComp2Wagers ] = useState(0);
    const dataRef = useRef();

    useEffect(() => {
        if (!matchup || !matchup.picks || !userProfile) return;
        if (matchup.picks[userProfile.appId]) {
            setPick(matchup.picks[userProfile.appId]);
            setPickedWager(matchup.picks[userProfile.appId].wager);
            dataRef.current = {
                ...dataRef.current, 
                ...{
                    'wager': matchup.picks[userProfile.appId].wager,
                    'pick': matchup.picks[userProfile.appId].pick,
                }
            }
            setFormData(dataRef.current);
        }
        if (matchup.wager === 'wager1') {
            setMinWager(100);
        } else if (matchup.wager === 'wager2') {
            setMinWager(300);
        } else if (matchup.wager === 'wager3') {
            setMinWager(500);
        } else if (matchup.wager === 'wager4') {
            setMinWager(800);
        }

        if (matchup.picks) {
            setComp1Percent(getPercentages(matchup.picks)[0]);
            setComp2Percent(getPercentages(matchup.picks)[1]);
            setComp1Wagers(getPercentages(matchup.picks)[2]);
            setComp2Wagers(getPercentages(matchup.picks)[3]);
        }

    }, [matchup, userProfile])

    useEffect(() => {
        if (!pick || !matchup) return;
        if (matchup.winner) {
            if (pick.pick === matchup.winner) {
                if (matchup.winner === 'comp1') {
                    setPick1Bg('picked-correct');
                    setPick2Bg('');
                } else {
                    setPick1Bg('');
                    setPick2Bg('picked-correct');
                }
            } else {
                if (matchup.winner === 'comp1') {
                    setPick1Bg('');
                    setPick2Bg('picked-wrong');
                } else {
                    setPick1Bg('picked-wrong');
                    setPick2Bg('');
                }
            }
        } else if (pick.pick === 'comp1') {
            setPick1Bg('picked');
            setPick2Bg('');
        } else if (pick.pick === 'comp2'){
            setPick1Bg('');
            setPick2Bg('picked');
        }
    }, [pick, matchup])

    function cancelPick() {
        dataRef.current = {...{}}
        setFormData(dataRef.current);
        setPickedWager('');
        setPick({...{}});
        closeModal();
        setComp1Percent(0);
        setComp2Percent(0);
        setLoading(false);
        setPick1Bg('');
        setPick2Bg('');
    }

    function saveData(key, value) {
        if (key === 'pick' && (!dataRef.current || !dataRef.current.wager)) {
            UIkit.modal.alert('You must make a wager before picking a winner.');
            return;
        }
        if (key === 'wager' && !value) {
            UIkit.modal.alert('You must make a wager before picking a winner.');
            return;
        }
        dataRef.current = {...dataRef.current, ...{[key]: value}}
        setFormData(dataRef.current);
        if (key === 'pick') {
            savePick('pick');
        }
        if (key === 'wager') {
            setPickedWager(value);
            if (dataRef.current.pick) {
                savePick('wager');
            }
        }
    }   

    async function savePick(pickWager) {
        if (pickWager === 'pick') {
            setLoading(true);
        }
        const res = await UseCloudFunction(
            'savePick', 
            {
                'gameId': currentGame.gameId,
                'matchupId': matchup.id,
                'pick': dataRef.current.pick,
                'wager': dataRef.current.wager,
                'appId': userProfile.appId,
                'ts': new Date().getTime(),
            }
        )
        console.log(res);
        toast(`${properCase(pickWager)} saved successfully!`)
        if (pickWager === 'pick') {
            cancelPick();
        }
    }

    return (
        <div className='pick-modal'>
            <Modal handleClose={cancelPick} cancel={cancelPick} show={matchup} buttonText={'Save Wager'} >
                {
                    (matchup) &&
                    <div className='modal-content'>
                        <div className='title'>{matchup.name}</div>
                        <div>{format(new Date(matchup.date), "E, Pp")}</div>
                        <div>{matchup.stadium}, {matchup.location}</div>
                        <div>{matchup.tv}</div>
                        <div className="g-space-1"></div>
                        {
                            (new Date().getTime() < matchup.date)
                            ? 
                            <div className='field'>
                                <label><b>Pick a Wager</b></label><br/>
                                <select 
                                    className="uk-select uk-form-small uk-form-width-small wager" 
                                    value={pickedWager}
                                    onChange={e => saveData('wager', Number(e.target.value))}  >	
                                {getWagers(matchup.wager, matchup.id)}
                                </select>
                            </div>
                            : 
                            (matchup.winner)
                            ?
                            <div>
                                <b>Result: {pick.pick === matchup.winner ? <span className='win'>+{pick.wager}</span> : <span className='loss'>-{pick.wager}</span>}</b>
                            </div>
                            :
                            <div>
                                <b>Wager: {pick.wager ?? minWager ?? ''}</b>
                                <div className='center-col'><FontAwesomeIcon icon={faLock} /></div>
                            </div>
                        }
                        <div className="g-space-1"></div>
                        <div className='icons-div'>
							<div className={`modal-img-div ${pick1Bg}`}>
								<img src={(matchup.comp1Icon) ? matchup.comp1Icon : question_mark} className='modal-img' />
								<div>{(matchup.comp1Rank) ? `#${matchup.comp1Rank}` : ''} {matchup.comp1}<br />
                                {matchup.comp1Info}</div>
								{
                                    (loading)
                                    ?
                                    <button className='g-button' ><ThreeDotsLoader /></button>
                                    :
									(new Date().getTime() < matchup.date && matchup.comp1)
									? <button className='g-button' onClick={() => saveData('pick','comp1')}>Pick</button>
									: ''
								}
                                {
                                    (matchup.comp1Details) &&
                                    <div className='comp-details'>{matchup.comp1Details}</div>
                                }
							</div>
							
							<div className={`modal-img-div ${pick2Bg}`}>
                                <img src={(matchup.comp2Icon) ? matchup.comp2Icon : question_mark} className='modal-img' />
								<div>{(matchup.comp2Rank) ? `#${matchup.comp2Rank}` : ''} {matchup.comp2}<br />
                                {matchup.comp2Info}</div>
								{
                                    (loading)
                                    ?
                                    <button className='g-button' ><ThreeDotsLoader /></button>
                                    :
									((new Date().getTime() < matchup.date) && matchup.comp2)
									? <button className='g-button' onClick={() => saveData('pick','comp2')}>Pick</button>
									: ''
								}
                                {
                                    (matchup.comp2Details) &&
                                    <div className='comp-details'>{matchup.comp2Details}</div>
                                }
							</div>
                            <div className={`pick-percentage ${comp1Percent >= 50 ? 'high' : 'low'}`}>
                                {comp1Percent}%
                                <div className='meta'>Tot Wagers: {comp1Wagers}</div>
                            </div>

                            <div className={`pick-percentage ${comp2Percent >= 50 ? 'high' : 'low'}`}>
                                    {comp2Percent}%
                                    <div className='meta'>Tot Wagers: {comp2Wagers}</div>
                                </div>
						</div>

                        {/* <tr>
                            <td>
                            {
                                (picks && picks[m.id]) &&
                                <div className={`pick-percentage ${(Object.values(picks[m.id]).filter(pick => pick === 'comp1').length / Object.values(picks[m.id]).length * 100) >= 50 ? 'high' : 'low'}`}>{
                                    (Object.values(picks[m.id]).filter(pick => pick === 'comp1').length / Object.values(picks[m.id]).length * 100).toFixed(0)
                                }%</div>
                            }
                            </td>
                            <td>
                            {
                                (picks && picks[m.id]) &&
                                <div className={`pick-percentage ${(Object.values(picks[m.id]).filter(pick => pick === 'comp2').length / Object.values(picks[m.id]).length * 100) >= 50 ? 'high' : 'low'}`}>{
                                    (Object.values(picks[m.id]).filter(pick => pick === 'comp2').length / Object.values(picks[m.id]).length * 100).toFixed(0)
                                }%</div>
                            }
                            </td>
                        </tr> */}
                    </div>
                }
            </Modal>
        </div>
    )
}

export default PickModal