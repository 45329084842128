import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './mobile-nav.styles.scss'
import { useSelector } from 'react-redux'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse, faUser, faCoins, faTrophy, faEllipsis, faPlus, faComments, faDoorOpen, faBullseye, faBell, faCalendarCheck, faChartLine, faChartSimple } from '@fortawesome/free-solid-svg-icons'

const MobileNav = ({ unread }) => {

	const currentURL = window.location.href
	const currentGame = useSelector(state => state.gameData.currentGame)
    const [ unreadChats, setUnreadChats ] = useState(0);
    const [ unreadNotifications, setUnreadNotifications ] = useState(0);
    
    useEffect(() => {
        if (!unread) return;
        setUnreadChats(Object.values(unread).filter(u => u.type === 'chat').length);
        setUnreadNotifications(Object.values(unread).filter(u => u.type === 'general').length);
    }, [unread])

	return (
		<div className='mobile-nav uk-hidden@m'>
			<Link to={(currentURL.includes('/game/')) ? '/game' : '/home'}>
				<div className='mobile-link' id='home-link'>
					<FontAwesomeIcon icon={(window.location.pathname === '/game' || window.location.pathname.includes('admin')) ? faDoorOpen : (window.location.pathname === '/home') ? faHouse : faCalendarCheck } />
				</div>
			</Link>
			{
				(!currentURL.includes('/game')) ?
					<Link to='/profile'>
						<div className='mobile-link' id='profile-link'>
							<FontAwesomeIcon icon={faUser} />
						</div>
					</Link>
				:''
			}
			{
				(!currentURL.includes('/game')) ?
				<Link to='/home/creategame'>
					<div className='mobile-link' id='create-game-link'>
						<FontAwesomeIcon icon={faPlus} />
					</div>
				</Link>
				: ''
			}
			
			{
				(currentURL.includes('/game') && !currentURL.includes('admin') && currentGame && currentGame.type === 'pick') ?
					<Link to='props'>
						<div className='mobile-link' id='props-link'>
							<FontAwesomeIcon icon={faCoins} />
						</div>
					</Link>
				: 
				(currentURL.includes('/game') && !currentURL.includes('admin') && currentGame && currentGame.type === 'hunt') ?
					<Link to=''>
						<div className='mobile-link' id='challenges-link'>
							<FontAwesomeIcon icon={faBullseye} />
						</div>
					</Link>
				: ''
			}
			{
				(currentURL.includes('/game')  && !currentURL.includes('admin')) &&
                <Link to='chat'>
                    <div className='mobile-link' id='chat-link'>
                        <div className="notification-with-badge">
                            <FontAwesomeIcon icon={faComments} title='Notifications' />
                            {
                                (unreadChats > 0) &&
                                <span className="mobile-badge">{unreadChats}</span>
                            }
                        </div>
                    </div>
                </Link>			
			}
			{
				(currentURL.includes('/game')  && !currentURL.includes('admin')) &&
                <Link to='leaders'>
                    <div className='mobile-link' id='leaders-link'>
                        <FontAwesomeIcon icon={faTrophy} />
                    </div>
                </Link>
			}
            {
				(currentURL.includes('/game')  && !currentURL.includes('admin')) &&
                <Link to='notifications'>
                    <div className='mobile-link' id='notifications-link'>
                        <div className="notification-with-badge">
                            <FontAwesomeIcon icon={faBell} title='Notifications' />
                            {
                                (unreadNotifications > 0) &&
                                <span className="mobile-badge">{unreadNotifications}</span>
                            }
                        </div>
                        {/* <FontAwesomeIcon icon={faBell} /> */}
                    </div>
                </Link>
			}
			
			<a data-uk-toggle="target: #sliding-user-info">
				<div className='mobile-link'>
					<FontAwesomeIcon icon={faChartSimple} />
				</div>
			</a>	
		</div>
	)
	
}

export default MobileNav