import React from 'react'
import './header.styles.scss'
import { useDispatch } from 'react-redux'
import banner_no_logo from '@/assets/banner-no-logo.png';


import SlidingMenu from '../sliding-menu/sliding-menu.component'

const Header = () => {

	return (
		<div className="header uk-hidden@m">
            <a className="uk-navbar-toggle uk-navbar-item uk-margin-left" data-uk-toggle="target: #sliding-menu" data-uk-navbar-toggle-icon></a>
			<img className="uk-margin-right header-img" src={banner_no_logo} alt='Pool Party logo' />
            {/* <div data-uk-sticky="sel-target: .uk-navbar-container; cls-active: uk-navbar-sticky; bottom: #transparent-sticky-navbar">
			    <nav className="uk-navbar-container navbar" data-uk-navbar >
			        <div className="uk-navbar-left">
							
			        </div>
					<div className="uk-navbar-right">
						<img className="uk-margin-right header-img" src={banner_no_logo} alt='Pool Party logo' />
					</div>
			    </nav>
			</div> */}
			<SlidingMenu />
		</div>
	)
}

export default Header

// <div 
// 				className="nav" 
// 				data-uk-sticky="cls-active: uk-background-secondary uk-box-shadow-medium; top: 90vh; animation: uk-animation-slide-top"
// 			>
// 				<nav className="uk-navbar uk-navbar-transparent uk-dark"  data-uk-navbar="mode: click">
// 					<div className="uk-navbar-left">
// 						<Link to="/home">
// 							<img className='header-img' src='../../../src/assets/icon.png' />
// 						</Link>		
// 					</div>
// 					<div className="uk-navbar-center">
// 						<div className="uk-navbar-item uk-padding-remove-horizontal">
// 							<Link to="/home">
// 								{
// 									// <img src="../../src/assets/banner3.png" className="small-img uk-hidden@s" alt="Logo" />
// 									// <img src="../../src/assets/banner3.png" className="wide-img uk-visible@s" alt="Logo" />
// 									// <ReactSVG className="hide small-img uk-hidden@s" src='../../src/assets/banner-logo3.svg' alt="Logo" />
// 									// <ReactSVG className="hide wide-img uk-visible@s" src='../../src/assets/banner-logo3.svg' alt="Logo" />		
// 								}
// 							</Link>
// 						</div>
// 					</div>
// 					<div className="uk-navbar-right">
// 						<ul className="uk-navbar-nav uk-visible@s">
// 							<a 
// 								className="uk-navbar-toggle uk-navbar-item uk-hidden@s" 
// 								uk-toggle="target: #sliding-menu" data-uk-navbar-toggle-icon 
// 							></a>	
// 						</ul>
// 					</div>
// 				</nav>
// 			</div>