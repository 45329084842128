import React, { useEffect } from 'react'
import './create-hunt-settings.styles.scss'

const CreateHuntSettings = ({ settings, setSettings }) => {

	useEffect(() => {
		
	}, [])

	const saveSettings = (e, cat) => {
		setSettings({...settings, ...{[cat]: e}})
	}
		
	return (
		<div className='create-settings'>
			
			<table className="uk-table uk-table-middle">
				<tbody>
					<tr>
						<td style={{'width': '40px'}}>
							<input className="uk-checkbox" type="checkbox" value={settings.isPublic} onChange={e => saveSettings(e.target.checked, 'isPublic')} />
						</td>
						<td>Allow players to request to join the game. If unchecked, the game will not appear on any lists.
						</td>
					</tr>
				</tbody>
			</table>

			
		</div>
	)
}

export default CreateHuntSettings