import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import './leaders.styles.scss'

import Modal from '@/components/modal/modal.component'
import UserInfoCol from '@/components/user-info-col/user-info-col.component'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCrow, faCrown, faTrophy } from '@fortawesome/free-solid-svg-icons'
import { useEffect } from 'react'
import { makeLinkActive } from '@/utils/active-links'
import { Link } from 'react-router-dom'
import { sortLeaders } from '@/utils/sortLeaders'

const Leaders = ({ front, leaders, matchups, hof }) => {

	const [ showLeaderInfo, setShowLeaderInfo ] = useState(false)
	const [ chosenLeaderId, setChosenLeaderId ] = useState(null);
    const [ leadersToDisplay, setLeadersToDisplay ] = useState(null);
	
    useEffect(() => {
        makeLinkActive('leaders-link')
    }, [])

    useEffect(() => {
        if (!leaders) return;
        setLeadersToDisplay(sortLeaders(leaders))
    }, [leaders])

	function displayLeaderModal(e) {
		setChosenLeaderId(e)
		setShowLeaderInfo(true)
		// document.getElementById('modal-children').scrollIntoView();
	}

	function closeLeaderModal() {
		setChosenLeaderId(null)
		setShowLeaderInfo(false)
	}
	
	return (
		<div className={`leaders page ${front ? 'no-margin': ''}`}>
			<div className='g-box head-box'>
				<span className='main'>
					<div>
                        <FontAwesomeIcon icon={faTrophy} className='head-icon' />
					    Leaderboard
                    </div>
                    {
                        (hof) &&
                        <Link to={`/game/leaders/hof`}>
                            <button className='g-button small-btn'>
                                HoF <FontAwesomeIcon icon={faCrown} className='crown-icon' />
                            </button>
                        </Link>
                    }
				</span>
				<hr />
			{
				(leadersToDisplay) &&
				
                leadersToDisplay.map((leader, index) => (
                    (!front || index === 0) &&
                    <div key={index}>
                        <table>
                            <tbody>
                                <tr>
                                    <td className="rank-cell">
                                        {Number(index) + 1}
                                    </td>
                                    <td className="img-cell">
                                        <img src={leader.icon} className="leader-icon" referrerPolicy="no-referrer" alt="game leader icon" loading="lazy" />
                                    </td>
                                    <td className="text-cell">
                                        <p className="desc">
                                            <button className='no-button' onClick={() => displayLeaderModal(leader.appId)}>
                                                <b>{leader.fName} {leader.lName}</b> 
                                                {
                                                    (hof && Object.values(hof.hof).filter(h => h.appId === leader.appId).length > 0) &&
                                                    <FontAwesomeIcon icon={faCrown} className='crown-icon' />
                                                }
                                            </button>
                                        </p>
                                        <p className="desc meta">
                                            <span>{leader.loc}</span>
                                        </p>
                                        {
                                        // <p className="desc meta">
                                        // 	Correct Picks: 
                                        // 	{
                                        // 		(picks && matchups) 
                                        // 		? (Object.values(picks[leader.uid])
                                        // 		.filter(pick => pick.pick == matchups[pick.id].winner).length / Object.values(matchups).filter(match => match.winner).length * 100).toFixed(1) + '%'
                                        // 		: '0%'
                                        // 	}
                                        // </p>
                                        }
                                    </td>
                                    <td className="total-cell">
                                        {(leader.matchPoints + leader.propPoints + leader.bonusPoints).toLocaleString()}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <hr />
                    </div>
                ))
			}
            {
                front &&
                <>
                    {/* <div className="g-height-20"></div> */}
                    <Link to='leaders' className=''>more...</Link>
                </>
            }
			</div>
			<Modal 
				handleClose={closeLeaderModal} 
				cancel={closeLeaderModal} 
				show={showLeaderInfo} 
				children={
					<div className='leader-info-container'>
						<UserInfoCol leaders={leaders} matchups={matchups} userId={chosenLeaderId} />
					</div>} 
				class2='grey'
				width='small' 
				height='med-height' 
				scrolling='modal-scroll' 
			/>
		</div>
	)
}

export default Leaders