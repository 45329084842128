import React, { useEffect, useState } from 'react'
import './chat-container.styles.scss'

import ChatInput from '@/components/chat/chat-input.component'
import ChatDisplay from '@/components/chat/chat-display.component'
import useWindowResize from '@/utils/windowSize'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComments } from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux'
import { UseCloudFunction } from '@/firebase-utils/firebase.utils'
import { makeLinkActive } from '@/utils/active-links'

const ChatContainer = ({ chats, leaders, unread }) => {

    const currentGame = useSelector(state => state.gameData.gameId);
    const appId = useSelector(state => state.userData.profile.appID)
	const [ width, height ] = useWindowResize();
	const [ replyId, setReplyId ] = useState(null)

    useEffect(() => {
        makeLinkActive('chat-link')
    })

	useEffect(() => {
		console.log(height)
	}, [height])

    
    
    // function clearAllUnread() {
    //     if (unread && Object.values(unread).filter(un => un.type === 'chat')) {
    //         console.log('clearing unread')
    //         setTimeout( async () => { 
    //             const res = await UseCloudFunction('clearAllUnread', {'type':'chat', 'gameId': currentGame.gameId, 'appId': appId})
    //         }, 2000)
    //     }
    // }
    
	return (
		<div className='chat-container page' id='chat-container'>
			
			<div className='g-box chat-box' id='chat-box' style={{height: height-245}}>
				<span className='main'>
					<FontAwesomeIcon icon={faComments} className='head-icon' />
					Chat
				</span>
				<hr />
				<ChatDisplay setReplyId={setReplyId} replyId={replyId} chats={chats} leaders={leaders} unread={unread} />
				<ChatInput replyId={replyId} />
			</div>
			
		</div>
	)
}

export default ChatContainer;