import React, { useState, useRef, useEffect } from 'react'
import './new-prop.styles.scss'
import { useSelector } from 'react-redux'

import { getDatabase, ref as dbRef, set } from "firebase/database"
import { UseCloudFunction } from '@/firebase-utils/firebase.utils'
import UIkit from 'uikit'

const NewProp = ({ matchups, propToEdit }) => {

    const currentGame = useSelector(state => state.gameData.currentGame)
	const [formData, setFormData] = useState('')
	const stateRef = useRef();

    useEffect(() => {
        if (!propToEdit) return;
        stateRef.current = {...propToEdit};
        setFormData(stateRef.current);
    }, [propToEdit])

	function saveData(key, data) {
        stateRef.current = {...stateRef.current, ...{[key]: data}};
		setFormData(stateRef.current);
	}
	
	const handleSubmit = async (e) => {
		e.preventDefault()
		const tempData = {...stateRef.current};
        const now = new Date().getTime()
        if (!tempData.id) {
            tempData.id = now + '_prop';
            tempData.type = 'prop';
            tempData.wager = 'wager2';
            tempData.status = 'active';
            tempData.gameId = currentGame.gameId;
        }
		const res = await UseCloudFunction('saveMatchup', {'matchup': tempData})
        if (res.error) {
            UIkit.modal.alert('Something went wrong: ' + res.error)
            return;
        }
        UIkit.modal.alert('Prop saved successfully!');
        stateRef.current = {}
        setFormData(stateRef.current);
	}

	const cancelSave = () => {
		stateRef.current = {}
        setFormData(stateRef.current);
	}
	
	return (
		<div className='new-prop'>
			<div className='g-box'>
				<form onSubmit={handleSubmit}>
                    <div className="uk-margin">
						<label>Prop Name</label>
						<input type="text" className="uk-input" value={formData.name ?? ''} 
							onChange={e => saveData('name', e.target.value)} />
					</div>
					<div className="uk-margin">
						<label>Description</label>
						<textarea type="text" rows="3" className="uk-textarea" value={formData.desc ?? ''} 
							onChange={e => saveData('desc', e.target.value)} ></textarea>
					</div>
					<div className="uk-margin">
						<label>Choice 1</label>
						<input type="text" className="uk-input" value={formData.comp1 ?? ''} 
							onChange={e => saveData('comp1', e.target.value)} />
					</div>
					<div className="uk-margin">
						<label>Choice 2</label>
						<input type="text" className="uk-input" value={formData.comp2 ?? ''} 
							onChange={e => saveData('comp2', e.target.value)} />
					</div>
					<div className="uk-margin">
						<label>Matchup to Lock Wagers</label>
						<select className="uk-select" value={formData.date ?? ''} 
								onChange={e => saveData('date', Number(e.target.value))}>
							<option value="">Choose a Matchup</option>
							{
								(matchups)
								? Object.values(matchups)
                                .sort((a,b) => (a.date > b.date) ? 1 : -1)
								.filter(match => match.date > new Date().getTime() && match.type === 'matchup')
								.map(match => (
									<option key={match.id} value={match.date}>{`${match.name} - ${match.comp1} v ${match.comp2}`}</option>
								))
								: ''
							}
						</select>
					</div>

					<div className='new-prop-buttons'>
						<button className='g-button' type='button' onClick={() => cancelSave()}>Cancel</button>
						<button className='g-button' type='submit' style={{'backgroundColor':'#03C04A'}} >Save</button>
					</div>
				</form>
			</div>
		</div>
	)
}

export default NewProp