import React, { useEffect, useRef, useState } from 'react'
import './homepage.styles.scss'

import { Routes, Route } from 'react-router-dom'
import SideNav from '../../components/side-nav/side-nav.component'
import UserInfoCol from '../../components/user-info-col/user-info-col.component'
import Header from '../../components/header/header.component'
import MobileNav from '../../components/mobile-nav/mobile-nav.component'

import CreateGame from '../../components/create-game/create-game.component'
import HomeLanding from '../../components/home-landing/home-landing.component'
import SlidingUserInfo from '../../components/sliding-user-info/sliding-user-info.component'
import { useDispatch, useSelector } from 'react-redux'
import { getGamesFromDb, getUniqueDocFromDb } from '@/utils/getDataFromDb'
import { setProfile } from '@/state/slices/userSlice'


const HomePage = () => {

	const userProfile = useSelector(state => state.userData.profile)
    const [ gamesList, setGamesList ] = useState(null);
    const gamesRef = useRef();
    let gamesListener;
	
    useEffect(() => {
        if (!userProfile) return;
        getGamesList();

        return () => {
            if (gamesListener) gamesListener();
        }
    }, [userProfile])

    

    function getGamesList() {
        getGamesFromDb({'appId': userProfile.appId, 'callback': callback, 'handleListener': handleListener})
        function callback(data) {
            gamesRef.current = {...gamesRef.current, ...{[data.gameId]: data}}
            setGamesList(gamesRef.current);
        }
        function handleListener(unsub) {
            gamesListener = unsub;
        }
    }

	return (
		<div>
			{
                (userProfile) &&
                <div className='homepage'>
                    <Header />
                    <SideNav />
                    <div className='user-info-col uk-visible@m'>
                        <UserInfoCol />
                    </div>
                    <MobileNav />
                    <SlidingUserInfo />
                    <div className='center-content'>
                        <Routes>
                            <Route path='/' element={
                                <HomeLanding gamesList={gamesList}/>
                            }></Route>
                            <Route path='/creategame' element={<CreateGame />}></Route>
                        </Routes>
                    </div>
                </div>
            } 
		</div>
	)
}

export default HomePage