import React, { useState } from 'react'
import './registration.styles.scss'

import { Link } from 'react-router-dom'

import UIkit from 'uikit'

import { auth } from '../../firebase-utils/firebase.utils'
import { createUserWithEmailAndPassword } from 'firebase/auth';
import banner_no_logo from '@/assets/banner-no-logo.png';

const Registration = () => {

	const [fName, setFName] = useState('')
	const [lName, setLName] = useState('')
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [confPassword, setConfPassword] = useState('')

	const handleSubmit = async (e) => {
		e.preventDefault()
		if (password !== confPassword) {
			UIkit.modal.alert("Passwords don't match")
			return;
		}
		try {
			const { user } = await createUserWithEmailAndPassword(auth, email, password)
			// setDisplayName('')
			setEmail('')
			setPassword('')
			setConfPassword('')
		} catch (error) {
            console.log(error.message)
			UIkit.modal.alert('Error creating account: ' + error.message)
		}
	}


	return (
		<div className='registration'>
            <img className='img' src={banner_no_logo} />
			<div className='g-box'>
                <h3>Register</h3><hr />
				<form onSubmit={handleSubmit}>
					<div className="uk-grid-small" data-uk-grid>
						<div className="uk-width-1-2@s">
							<input className="uk-input" type="text" placeholder="First Name" onChange={(e) => setFName(e.target.value)} />
						</div>
						<div className="uk-width-1-2@s">
							<input className="uk-input" type="text" placeholder="Last Name" onChange={(e) => setLName(e.target.value)} />
						</div>
						<div className="uk-width-1-1">
							<input className="uk-input" type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
						</div>
						<div className="uk-width-1-2@s">
							<input className="uk-input" type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
						</div>
						<div className="uk-width-1-2@s">
							<input className="uk-input" type="password" placeholder="Confirm Password" onChange={(e) => setConfPassword(e.target.value)} />
						</div>
					</div>
					<div className='buttons uk-margin'>
						<Link to='/'>
							<button className='g-button'>
								Cancel
							</button>
						</Link>
						<button className='g-button primary' type="submit">
							Complete Registration
						</button>
						{/* <CustomButton onClick={signInWithGoogle} isGoogleSignIn>
							Sign In With Google
						</CustomButton>*/} 
					</div>
				</form>
			</div>
		</div>
	)
}

export default Registration