import React, { useEffect, useRef } from "react"
import './commish-list.styles.scss'

import { useSelector, useDispatch } from 'react-redux'
import { setArchivedCommishGames, setCommishGames, setCurrentGame } from "@/state/slices/gameSlice"
import { useNavigate } from 'react-router-dom'
import { collection, getDocs, query, where } from "firebase/firestore"
import { db } from "@/firebase-utils/firebase.utils"
import { getUniqueDocFromDb } from "@/utils/getDataFromDb"

const CommishList = ({ commishList }) => {
	
	const navigate = useNavigate()
	const dispatch = useDispatch()    

	function openGame(id) {
		console.log(id)
        getUniqueDocFromDb({'collection': 'games', 'docId': id, 'callback': callback, 'once': true})
        function callback(data) {
            dispatch(setCurrentGame(data));
            navigate('/gameadmin');
        }
	}
	
	return (
		<div className='commish-list'>
			<div className='g-box'>
				<h3>Games I'm Running</h3>
				<hr />
				<div className='game-list'>
					{
						(commishList.length > 0) ?
							commishList.map(game => (
                                <button key={game.gameId} className='g-button game-box' onClick={() => openGame(game.gameId)}>
									<img className='game-icon' src={game.icon} alt='game icon' />
									<div className='game-info'>{game.title}</div>
								</button>
							))
						: ''
					}
				</div>
			</div>
		</div>
	)
}

export default CommishList