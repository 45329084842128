import React from 'react'
import './icon-modal.styles.scss'

import Modal from '@/components/modal/modal.component'

import icons from './icons'

const IconModal = ({ handleClose, show, setIcon }) => {

	function chooseIcon(url) {
		setIcon(null)
		console.log(url)
		setIcon({upload: false, url: url})
		handleClose();
	}
	
	return (
		<div className='icon-modal'>
			<Modal 
				handleClose={handleClose} 
				cancel={handleClose} 
				show={show} 
				children={
					<div className='icons'>
						{
							icons.map(img => (
								<div key={img.id} className='profile-icons' onClick={(e) => chooseIcon(img.url)}><img src={img.url} alt='profile icon' /></div>
							))
						}
					</div>
				} 
				text='Choose an Icon'
				width='wide'
				height='med-height'
				scrolling='modal-scroll'
			/>
		</div>
	)
}

export default IconModal;
