import React, { useEffect, useState } from 'react';
import './new-message-modal.styles.scss';
import Modal from '../modal/modal.component';
import { format } from 'date-fns';
import { UseCloudFunction } from '@/firebase-utils/firebase.utils';
import { useSelector } from 'react-redux';

const NewMessageModal = ({ unread, notifications }) => {

    const userProfile = useSelector(state => state.userData.profile);
    const currentGame = useSelector(state => state.gameData.currentGame);
    const [ newMessage, setNewMessage ] = useState(null);

    useEffect(() => {
        if (!unread || !notifications) return;
        delete unread.status
        const newMessages = Object.values(unread).filter(u => u.type === 'general')
        if (newMessages.length > 0) {
            setNewMessage(notifications[newMessages[0].id]);
        }
    }, [unread, notifications])

    async function closeModal() {
        const obj = {
            'noteId': newMessage.id,
            'appId': userProfile.appId,
            'gameId': currentGame.gameId,
        }
        setNewMessage(null);
        const res = await UseCloudFunction('removeUnread', obj);
        console.log(res);
    }

    return (
        <div className='new-message-modal'>
            <Modal show={newMessage} cancel={() => setNewMessage(null)} scrolling={'modal-scroll'} hideCloseButton={true} class2='no-pad'>
                {
                    (newMessage) &&
                    <>
                    <div className='new-message-content'>
                        <div className='new-message-header'>
                            <div>
                                <img src={newMessage.icon} className='header-icon' />
                            </div>
                            <div>
                                <h4>{newMessage.title}</h4>
                                <div className='date'>{format(new Date(newMessage.ts), 'Pp')}</div>
                            </div>
                        </div>
                        <div className="g-space-1"></div>
                        <p className='new-message-text'>{newMessage.text}</p>
                        <div className='buttons'>
                            <button className='g-button' onClick={() => closeModal()}>Close</button>
                        </div>
                    </div>
                    
                    </>
                }
            </Modal>
        </div>
    )
}

export default NewMessageModal