import React, { useState } from 'react'
import './manage-challenges.styles.scss'

import { Link } from 'react-router-dom'
import ChallengeList from '../challenge-list/challenge-list.component'
import NewChallenge from '../new-challenge/new-challenge.component'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBullseye } from '@fortawesome/free-solid-svg-icons'

const ManageChallenges = () => {

	const [ challenge, setChallenge ] = useState({
		'upload': false,
		'approval': false
	});
	
	return (
		<div className='manage-challenges'>
			<div className='g-container no-pad'>
				<Link to='/gameadmin'>
					<button className='g-button'>Back</button>
				</Link>
				<div className='manage-challenges-content'>
					<div className='half-col'>
						<NewChallenge challenge={challenge} setChallenge={setChallenge} />
					</div>
					<div className='half-col'>
						<div className='uk-accordion-title g-box head-box'>
							<h4 className='main'>
								<FontAwesomeIcon icon={faBullseye} className='head-icon' />
								Created Challenges
							</h4>
							<hr />
							
							<ChallengeList setChallenge={setChallenge} />
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ManageChallenges