import { createAction, createSlice } from '@reduxjs/toolkit'

const initialState = {
    currentToken: null,
    currentUser: null,
    myTokens: null,
    profile: null,
    savedToken: null,
}

export const revertAll = createAction('REVERT_ALL')

export const userSlice = createSlice({
    name: 'user',
    initialState,
    extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
    reducers: {
        setCurrentToken: (state, action) => {
            state.currentToken = action.payload
        },
        setCurrentUser: (state, action) => {
            state.currentUser = action.payload
        },
        setMyTokens: (state, action) => {
            state.myTokens = action.payload
        },
        setProfile: (state, action) => {
            state.profile = action.payload
        },
        setSavedToken: (state, action) => {
            state.savedToken = action.payload
        },
        resetUserData: (state) => (state = initialState),
        
    },
})

// Action creators are generated for each case reducer function
export const { 
    setCurrentToken,
    setCurrentUser, 
    setMyTokens,
    setProfile,
    setSavedToken,
    resetUserData
} = userSlice.actions

export default userSlice.reducer