import React, { useEffect, useState } from 'react'
import './my-games.styles.scss'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Modal from '../../modal/modal.component'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faUserPlus } from '@fortawesome/free-solid-svg-icons'
import { version } from '@/changelog-version'
import { UseCloudFunction } from '@/firebase-utils/firebase.utils'
import ThreeDotsLoader from '@/components/loader/three-dots-loader.component'
import { getUniqueDocFromDb } from '@/utils/getDataFromDb'
import { setCurrentGame } from '@/state/slices/gameSlice'
import UIkit from 'uikit'

const MyGames = ({ playerList, openList }) => {

	const userProfile = useSelector(state => state.userData.profile)
    const dispatch = useDispatch()
	const navigate = useNavigate()
	const [ showModal, setShowModal ] = useState(false)
    const [ loadingJoin, setLoadingJoin ] = useState(false)
	
	useEffect( () => {
        console.log('getting my games')
        // getMyGames()
	}, [])

    function openGame(id) {
		getUniqueDocFromDb({'collection': 'games', 'docId': id, 'callback': callback, 'once': true})
        function callback(data) {
            dispatch(setCurrentGame(data));
            navigate('/game');
        }
	}

    async function joinGame(gameId) {
        setLoadingJoin(true);
        const res = await UseCloudFunction('joinGame', {'user': userProfile, 'gameId': gameId, 'ts': new Date().getTime()})
        console.log(res);
        if (res.error) {
            UIkit.modal.alert('Something went wrong: ' + res.error);
            setShowModal(false);
            return;
        }
        openGame(gameId);
    }
	
	return (
		<div className='my-games'>
			<div className='g-box'>
				<div className='box-head'>
                    <h3>My Games</h3>
                    <div className='version'>{version}</div>
                </div>
				<hr />
				<div className='game-list'>
					<button className='g-button game-box' onClick={() => setShowModal(true)}>
						<div className='open-game-icon'>
                            <FontAwesomeIcon icon={faUserPlus} title="Create New Game" size="2x" />
                        </div>
                        <div className='title'>Join an open game</div>
					</button>
					{
						(playerList) ?
							playerList.filter(g => !g.archived)
                            .map(game => (
                                <button key={game.gameId} className='g-button game-box hover' onClick={() => openGame(game.gameId)}>
									<img className='game-icon' src={game.icon} />
									<div className='title'>{game.title}</div>
								</button>
							))
						: ''
					}
				</div>
                
			</div>
			<Modal  handleClose={() => setShowModal(false)} cancel={() => setShowModal(false)} show={showModal}  text={'Poop'} class2='open-game-modal' width='med' children={<>
				{
					(openList) ?
						<>
						<h4>Join an open game...</h4><hr />
						<div className='scrolling game-list'>
							{
								openList.map(game => (
									<div key={game.gameId} className='game-box open-game'>
										
                                        <img className='game-icon' src={game.icon} />
                                        
                                        <div className='game-info'>
                                            <div className='game-title'>{game.title}</div>
                                            <div>{game.desc}</div>
                                        </div>
                                        
                                        {
                                            (loadingJoin) 
                                            ?
                                            <button className='g-button join' ><ThreeDotsLoader /></button>
                                            :
                                            <button className='g-button join' onClick={() => joinGame(game.gameId)}>Join</button>	
                                        }
									</div>
									
								))
							}
						</div>
						</>
					: ''
				}
				</>}
			/>
		</div>
	)
}

export default MyGames

//async function getMyGames() {
	// 	const rtDb = getDatabase();
	// 	const registeredRef = ref(rtDb, `regs/byUser/${profile.appID}`);
	// 	console.log(profile.appID)
	// 	onValue(registeredRef, async (snapshot) => {
	// 		if (snapshot.exists()) {
	// 			const list = Object.keys(snapshot.val());
	// 			// console.log(snapshot.val());
	// 			let obj = {}
	// 			for (let l of list) {
	// 				const gameRef = ref(rtDb, `gameList/${l}`);
	// 				const gameData = await get(gameRef, (gameSnap) => {
	// 					return gameSnap.val();
	// 				})
	// 				if (!gameData.val().archived) {
    //                     obj[gameData.val().id] = gameData.val()
    //                 } else {
    //                     dispatch(setArchivedMyGames({...archivedMyGames, ...{[gameData.val().id]: gameData.val()}}))
    //                 }
	// 			}
	// 			console.log(obj)
	// 			dispatch(setMyGames(obj))
	// 		}
	// 	}, (error) => console.log(error));
	// }

	

	// function launchOpenGames() {
	// 	let list = openGames
	// 	if (!openGames) {
	// 		const openGamesRef = collection(db, "games");
	// 		const q = query(openGamesRef, where('isPublic', '==', true), where('archived', '==', false))
	// 		list = {}
	// 		let count = 0
	// 		const results = onSnapshot(q, (querySnapshot) => {
	// 			querySnapshot.forEach((doc) => {
	// 				const data = doc.data()
	// 				if (!myGames || !myGames[data.id] && !data.archived) {
	// 					list[data.id] = data
	// 				}
	// 			});
	// 			console.log(list)
	// 			setOpenGames(Object.values(list).sort((a,b) => (a.ts > b.ts) ? 1 : -1))
	// 		});
	// 	}
	// 	setShowModal(true)
	// }

	// const closeModal = () => {
	// 	setShowModal(false)
	// }

	// async function joinGame (gameId) {
	// 	console.log('joining game')
	// 	const approved = await UIkit.modal.confirm('Are you sure you want to join this game?').then(() => {
	// 		console.log('approved')
	// 		return true;
	// 	}, () => {
	// 		console.log('declined')
	// 		return false;	
	// 	})
	// 	console.log(approved)
	// 	if (!approved) {
	// 		return;
	// 	}
	// 	const data = {
	// 		user: profile,
	// 		gameId: gameId,
	// 		ts: new Date().getTime()
	// 	}
	// 	console.log(data)
    //     dispatch(setCurrentToken(null))
	// 	UseCloudFunction('joinGame', data)
	// 	.then(res => {
	// 		console.log(res)
	// 		if (res && res.gameData) {
	// 			setTimeout(() => {
	// 				closeModal()
	// 				const newOpenGames = openGames
	// 				delete newOpenGames[gameId]
	// 				setOpenGames(newOpenGames)
	// 				dispatch(setMyGames({...myGames, ...{[gameId]: res.gameData}}))
	// 			}, 1000)
	// 		}
	// 	})
	// 	.catch(err => {
	// 		console.log(err.stack)
	// 	})
	// }