import React, { useState } from 'react'
import './file-upload.styles.scss'

const FileUpload = ({ setUploadedFile }) => {

	const handleUpload = (file) => {
		setUploadedFile(file);
		console.log(file.name)
		document.getElementById('upload-name').innerHTML = file.name;
		// setUploadedFile(file);
		document.getElementById('upload-button').classList.add('uploaded')
	}
	
	return (
		<div className='file-upload'>
			<div className="upload-div">
				<div data-uk-form-custom style={{'cursor': 'pointer'}}>
					<input 
						type="file" 
						id="fileUpload" 
						onChange={(e) => handleUpload(e.target.files[0])}  />
					<button 
						className="g-button"
						id="upload-button"
						type="button" 
						tabIndex="-1"
					>Upload a file</button>
				</div>
			</div>
			<span id='upload-name'>No file uploaded...</span>
			
		</div>
	)
}

export default FileUpload;