import React, { useEffect, useState } from 'react'
import './new-results-modal.styles.scss';
import Modal from '@/components/modal/modal.component';
import { useDispatch, useSelector } from 'react-redux';
import { UseCloudFunction } from '@/firebase-utils/firebase.utils';
import { setLeaders } from '@/state/slices/gameSlice';
import streak_bonus from '@/assets/streak-bonus.png';
import prop_bet from '@/assets/prop-bet.png';

const NewResultsModal = ({ unread, matchups, leaders }) => {

    const currentGame = useSelector(state => state.gameData.currentGame);
    const profile = useSelector(state => state.userData.profile);
    const [ showNewResults, setShowNewResults ] = useState(false);
    const [ userData, setUserData ] = useState(null);
    const [ totalUnviewed, setTotalUnviewed ] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!profile || !leaders || !matchups || !unread) {return;}
        const user = leaders[profile.appID];
        setUserData(user);
        if (unread && Object.values(unread).filter(u => u.type === 'matchup' || u.type === 'prop' || u.type === 'bonus').length > 0) {
            setShowNewResults(true);
        }
    }, [profile, leaders, matchups, unread])

    useEffect(() => {
        if (!unread || Object.values(unread).filter(u => u.type === 'matchup' || u.type === 'prop' || u.type === 'bonus').length === 0) {return;}
        console.log(unread);
        setTotalUnviewed(Object.values(unread).filter(u => u.type === 'matchup' || u.type === 'prop' || u.type === 'bonus').reduce((acc, cur) => acc + cur.points, 0));
    }, [unread])

    async function markAsViewed() {
        setShowNewResults(false);
        const listToClear = Object.values(unread).filter(u => u.type === 'matchup' || u.type === 'prop' || u.type === 'bonus');
        console.log(listToClear)
        const res = await UseCloudFunction(
            'clearUnviewed', 
            {
                'appId': profile.appId, 
                'gameId': currentGame.gameId, 
                'unread': listToClear,
            }
        );
        console.log(res);
    }

    return (
        <div className='new-results-modal'>
            <Modal show={showNewResults} cancel={() => setShowNewResults(false)} scrolling={'modal-scroll'} hideCloseButton={true} >
                
                {
                    (unread && Object.values(unread).length > 0 && currentGame && currentGame.settings) &&
                    <>
                    <h2>New Results!</h2>
                    {
                        Object.values(unread).filter(u => u.type === 'matchup' || u.type === 'prop' || u.type === 'bonus').sort((a,b) => a.matchId > b.matchId ? 1 : -1).map(un => {
                            if (un.type === 'bonus'){
                                return (
                                    <div key={un.matchId} className='result-box bonus'>
                                        <img src={streak_bonus} />
                                        <div className='game-results'>
                                        <div className='title'>Streak Bonus!</div>
                                        <div className='meta'>{currentGame.settings.streakCount} in a Row</div>
                                        <div className={`result-pts win`}>{`+${un.points}`}</div>
                                    </div>
                                    </div>
                                )
                            }
                            const matchup = matchups[un.matchId];
                            console.log(matchup);
                            const winner = matchup.winner;
                            const loser = winner === 'comp1' ? 'comp2': 'comp1';
                            return (
                                <div key={un.matchId} className={`result-box match ${un.result}`}>
                                    <img src={un.type === 'matchup' ? un.result === 'win' ? matchup[`${winner}Icon`] : matchup[`${loser}Icon`] : prop_bet} />
                                    <div className='game-results'>
                                        <div className='title'>{matchup.name}</div>
                                        <div className='meta'>Winner: {matchup[winner]}</div>
                                        <div className={`result-pts ${un.result}`}>{un.result === 'win' ? '+' :  ''}{un.points}</div>
                                        
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div className='total g-box with-border'>
                        Points {totalUnviewed >= 0 ? 'Earned' : 'Lost'}: <span className={totalUnviewed >= 0 ? 'green' : 'red'}>{totalUnviewed >= 0 ? '+' : ''}{totalUnviewed}</span>
                    </div>
                    <hr />
                    <div className='buttons'>
                        <button className='g-button' onClick={() => markAsViewed()}>OK</button>
                    </div>
                    </>
                }
            </Modal> 
        </div>
    )
}

export default NewResultsModal