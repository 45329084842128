import { db } from '@/firebase-utils/firebase.utils'
import { get, getDatabase, onValue, ref } from 'firebase/database'
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore'
import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setArchivedCommishGames, setArchivedMyGames, setMyGames } from '@/state/slices/gameSlice'
import { setProfile } from '@/state/slices/userSlice'
import './user-load.styles.scss'

const UserLoad = () => {
    
    const profile = useSelector(state => state.userData.profile)
	const currentUser = useSelector(state => state.userData.currentUser)
    const navigate = useNavigate();
    const dispatch = useDispatch();

	useEffect(() => {
        if (currentUser) { 
            // dispatch(setCompetitors(null))
            async function getData() {
                const docRef = doc(db, 'users', currentUser.email)
				const docSnap = await getDoc(docRef)
				let profile = (docSnap.exists()) ? docSnap.data() : {}
				if (profile.email) {
					//SET PROFILE
					dispatch(setProfile(profile))
                    navigate('/home')
				} else {
					navigate('/profile')
				}
                // const comm = await getCommishGames();
                // const my = await getMyGames();
                
            }
            getData();
        }
	}, [currentUser])

	

    

    return (
        <div className='user-load'>
            <div className="loader-spinner"></div>
        </div>
    )
}

export default UserLoad